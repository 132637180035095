import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import styled from "styled-components";

import useWindowSize from "../lib/useWindowResize";
import { BOTTOM_BAR_OFFSET } from "../resources/constants";
import { color } from "../resources/styles";
import { Button } from "./base-components";

const Container = styled.div`
  padding: 0 5px;
  overflow: hidden;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 5px;
  width: auto;
  padding: 10px;
  margin: 0;
  z-index: 10;
  background-color: transparent;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: ${color.blue};
`;

type Props = {
  children: JSX.Element;
  animateResize?: boolean;
  isMinimised: boolean;
  onChange: (isMinimised: boolean) => void;
  collapsable: boolean;
};

const Collapsable = (props: Props): JSX.Element => {
  const [isMinimised, setIsMinimised] = useState(false);

  const [currentHeight, setCurrentHeight] = useState<number | string>("auto");
  const { width, height, ref } = useResizeDetector();
  const windowSize = useWindowSize();

  const onClickMinimise = () => {
    const state = !isMinimised;
    setIsMinimised(state);
    props.onChange(state);
  };

  useEffect(() => {
    if (props.isMinimised !== isMinimised) {
      setIsMinimised(props.isMinimised);
    }
  }, [props.isMinimised]);

  useEffect(() => {
    if (props.animateResize === true && height !== currentHeight) {
      if (height === undefined) {
        setCurrentHeight("auto");
      } else {
        let newHeight = height + 15;
        if (windowSize.height && windowSize.height < height) {
          newHeight = windowSize.height;
        }
        setCurrentHeight(newHeight);
      }
    }
  }, [props.animateResize, width, height]);

  return (
    <Container
      style={{
        height: isMinimised ? BOTTOM_BAR_OFFSET : currentHeight,
        transition: props.animateResize ? "height 0.3s" : "none",
      }}
    >
      {props.collapsable && (
        <CloseButton onClick={onClickMinimise}>
          <CloseIcon icon={isMinimised ? faChevronUp : faChevronDown} />
        </CloseButton>
      )}

      <div ref={ref}>{props.children}</div>
    </Container>
  );
};

export default Collapsable;
