import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import ProductContext from "../contexts/ProductContext";
import { color } from "../resources/styles";
import { Product, ProductVariation, VariationType } from "../types";
import { Button, Flex1, FlexRow, TitleSmall } from "./base-components";
import SliderPill from "./SliderPill";
import SliderTile from "./SliderTile";

const MainTitle = styled(TitleSmall)`
  padding-right: 30px;
`;

const BackButton = styled(Button)`
  flex: 0;
  padding: 10px;
  margin: 0;
  background-color: transparent;
`;

const BackIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: ${color.blue};
`;

const SlideContainer = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

type Props = {
  windowIndex: number;
  product: Product;
  visible: boolean;
  onClickBack?: () => void;
};

const ProductVariationSelector = observer((props: Props) => {
  const productContext = useContext(ProductContext);

  const [selectedVariationTypeId, setSelectedVariationTypeId] = useState(-1);
  const [selectedExtras, setSelectedExtras] = useState<number[]>([]);

  useEffect(() => {
    if (props.product.variation_types.length) {
      setSelectedVariationTypeId(props.product.variation_types[0].id);
    }
  }, [props.product]);

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const onClickVariationType = (variationType: VariationType) => {
    setSelectedVariationTypeId(variationType.id);
  };

  const onClickExtra = (variationType: VariationType) => {
    const group = variationType.pivot.group;
    // Remove any extras that match this group
    const _extras = [...selectedExtras];
    if (group) {
      for (const extra of props.product.extras) {
        if (
          extra.pivot.group &&
          extra.pivot.group.toLowerCase() === group.toLowerCase() &&
          extra.id !== variationType.id
        ) {
          if (_extras.indexOf(extra.id) > -1) {
            _extras.splice(_extras.indexOf(extra.id), 1);
          }
        }
      }
    }
    if (_extras.indexOf(variationType.id) > -1) {
      _extras.splice(_extras.indexOf(variationType.id), 1);
    } else {
      _extras.push(variationType.id);
    }
    setSelectedExtras(_extras);

    productContext.setProductExtrasSelection(
      props.windowIndex,
      props.product.id,
      _extras,
      true
    );
  };

  const onClickProductVariation = (productVariation: ProductVariation) => {
    productContext.setProductVariationSelection(
      props.windowIndex,
      productVariation,
      true
    );
  };

  const getTitle = (): string => {
    let title = props.product.title;
    if (props.product.category !== null) {
      title = props.product.category.title + " - " + title;
    }
    return title;
  };

  const isVariationActive = (variation: ProductVariation) => {
    const productSelection = productContext.getProductSelection(
      props.windowIndex
    );
    if (productSelection && productSelection.variationSelections) {
      return (
        productSelection.variationSelections[variation.variation_type_id] !==
          undefined &&
        productSelection.variationSelections[variation.variation_type_id].id ===
          variation.id
      );
    }
    return false;
  };

  const getExtrasOptions = () => {
    // Only show extras that can be synced to the current variation selections

    const availableExtraIds: number[] = [];
    const variations = productContext.getProductVariationSelection(
      props.windowIndex
    );
    for (const variation of variations) {
      for (const syncedWithId of variation.synced_with_ids) {
        const syncedExtra = productContext.variationMap.get(syncedWithId);
        if (syncedExtra) {
          availableExtraIds.push(syncedExtra.variation_type.id);
        }
      }
    }
    return props.product.extras.filter((item) => {
      return availableExtraIds.indexOf(item.id) > -1;
    });
  };

  return (
    <div
      style={{
        display: props.visible ? "block" : "none",
      }}
    >
      <FlexRow justify={"center"} align={"center"}>
        <BackButton onClick={onClickBack}>
          <BackIcon icon={faChevronLeft} />
        </BackButton>
        <Flex1>
          <MainTitle>{getTitle()}</MainTitle>
        </Flex1>
      </FlexRow>
      <SlideContainer>
        {props.product.variation_types.map((variationType) => {
          return (
            <SliderPill
              key={`variation-type-${variationType.id}`}
              title={variationType.title}
              active={selectedVariationTypeId === variationType.id}
              onClick={() => onClickVariationType(variationType)}
            />
          );
        })}
        {getExtrasOptions().map((variationType) => {
          return (
            <SliderPill
              key={`variation-type-${variationType.id}`}
              title={variationType.title}
              active={selectedExtras.indexOf(variationType.id) > -1}
              isExtra={true}
              onClick={() => onClickExtra(variationType)}
            />
          );
        })}
      </SlideContainer>
      <SlideContainer>
        {props.product.product_variations.map((productVariation) => {
          if (productVariation.variation_type_id === selectedVariationTypeId) {
            return (
              <SliderTile
                size={"small"}
                key={`variation-${productVariation.id}`}
                title={productVariation.title}
                image={productVariation.image}
                active={isVariationActive(productVariation)}
                onClick={() => onClickProductVariation(productVariation)}
              />
            );
          }
        })}
      </SlideContainer>
    </div>
  );
});

export default ProductVariationSelector;
