import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext } from "react";

import { QuoteRequest, Settings } from "../types";

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  emailAddress: string | null = null;
  otpCode: string | null = null;
  hasDismissedCameraModal = false;
  isGetAQuoteModalVisible = false;
  isSubmittingLead = false;
  isLeadWindowDimensionsSet = false;
  isLeadContactSubmitted = false;
  windowWidths: string[] = [];
  windowHeights: string[] = [];
  appSettingsLoading = true; // will change to false
  loginSubmitting = false;
  isQuoteRequestSubmitting = false;
  appSettings: Settings = {
    login_method: "code",
    welcome_message:
      "Thinking about updating your window with some new blinds but want to ‘try before you buy’? Test out our new HomeView app! This app guides you through a simple step-by-step process so you can see what our range of blinds, awnings and shutters will actually look like on your windows.",
    start_btn_text: "Start HomeView",
    quote_modal_confirm_btn_text: "Get a Quote",
    quote_modal_cancel_btn_text: "Not Yet",
    dimensions_title_text: "What are the dimensions of your window",
    window_measure_video: "",
    dimensions_video_subtitle_text: "Watch video on how to measure blinds",
    dimensions_submit_btn_text: "Submit",
    contact_details_title_text: "Contact Details",
    contact_details_btn_text: "Submit",
    start_over_btn_text: "Start Over",
    screenshot_btn_text: "Download Preview",
    submit_success_message:
      "Request Sent!\n\nWe'll be in contact soon with your quote!",
  };

  reset() {
    this.emailAddress = null;
    this.hasDismissedCameraModal = false;
    this.isGetAQuoteModalVisible = false;
    this.isSubmittingLead = false;
    this.isLeadWindowDimensionsSet = false;
    this.isLeadContactSubmitted = false;
    this.windowWidths = [];
    this.windowHeights = [];
  }

  set setDismissedCameraModal(value: boolean) {
    this.hasDismissedCameraModal = value;
  }

  set setGetAQuoteModalVisible(value: boolean) {
    this.isGetAQuoteModalVisible = value;
  }

  set setSubmittingLead(value: boolean) {
    this.isSubmittingLead = value;
  }

  set setLeadWindowDimensionsSet(value: boolean) {
    this.isLeadWindowDimensionsSet = value;
  }

  set setLeadContactSubmitted(value: boolean) {
    this.isLeadContactSubmitted = value;
  }

  set setWindowWidths(value: string[]) {
    this.windowWidths = value;
  }

  set setWindowHeights(value: string[]) {
    this.windowHeights = value;
  }

  async loadAppSettings() {
    this.appSettingsLoading = true;
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/settings`
    );
    runInAction(() => {
      this.appSettings = result.data;
      this.appSettingsLoading = false;
    });
  }

  async submitQuoteRequest(request: QuoteRequest) {
    runInAction(() => {
      this.isQuoteRequestSubmitting = true;
    });
    await axios.post(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/quote-request`,
      request
    );
    runInAction(() => {
      this.isQuoteRequestSubmitting = false;
    });
  }
}

const UserContext = createContext(new UserStore());

export default UserContext;
