import { makeAutoObservable } from "mobx";
import React, { createContext } from "react";
import Webcam from "react-webcam";

class CameraStore {
  constructor() {
    makeAutoObservable(this);
  }

  webcamRef: React.RefObject<Webcam & HTMLVideoElement> | null = null;
  warningAccepted = false;
  cameraError = false;
  cameraStarted = false;
  imageConfirmed = false;
  cameraImage: string | null = null;

  reset() {
    this.warningAccepted = false;
    this.cameraError = false;
    this.cameraStarted = false;
    this.imageConfirmed = false;
    this.cameraImage = null;
  }

  acceptWarning() {
    this.warningAccepted = true;
  }

  handleCameraError() {
    this.cameraError = true;
  }

  clearCameraError() {
    this.cameraError = false;
  }

  startCamera() {
    this.cameraError = false;
    this.cameraStarted = true;
  }

  setWebcamRef(ref: React.RefObject<Webcam & HTMLVideoElement> | null) {
    this.webcamRef = ref;
  }

  captureImage() {
    if (this.webcamRef && this.webcamRef.current) {
      this.cameraImage = this.webcamRef.current.getScreenshot();
    }
  }

  resetImage() {
    this.cameraImage = null;
  }

  confirmImage() {
    this.imageConfirmed = true;
  }
}

export const CameraContext = createContext(new CameraStore());

export default CameraContext;
