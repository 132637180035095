import {
  faCamera,
  faCheck,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import styled from "styled-components";

import CameraContext from "../contexts/CameraContext";
import { color } from "../resources/styles";
import {
  ActionButton,
  ActionButtonAlt,
  Button,
  ButtonContainer,
  ButtonIcon,
  FlexColumn,
  Paragraph,
  ParagraphBold,
} from "./base-components";

const RetakeButton = styled(ActionButtonAlt)`
  padding-left: 40px;
  margin-bottom: 10px;
`;
const ContinueButton = styled(ActionButton)`
  padding-left: 40px;
  margin-bottom: 10px;
`;

const SmallButtonIcon = styled(ButtonIcon)`
  font-size: 1.2rem;
`;

const CameraIcon = styled(FontAwesomeIcon)`
  color: ${color.white};
  font-size: 35px;
`;

const CameraCapture = observer(() => {
  const cameraContext = useContext(CameraContext);

  const onClickAcceptWarning = () => {
    cameraContext.acceptWarning();
  };

  const onClickClearError = () => {
    cameraContext.clearCameraError();
  };

  const onClickCapture = () => {
    cameraContext.captureImage();
  };

  const onClickRetake = () => {
    cameraContext.resetImage();
  };

  const onClickContinue = () => {
    cameraContext.confirmImage();
  };

  return (
    <FlexColumn align={"center"}>
      {!cameraContext.warningAccepted ? (
        <>
          <Paragraph align={"center"}>We need to access your camera</Paragraph>
          <Button onClick={onClickAcceptWarning}>Enable Camera</Button>
        </>
      ) : cameraContext.cameraError ? (
        <>
          <ParagraphBold align={"center"}>
            Failed to access camera
          </ParagraphBold>
          <Paragraph align={"center"}>
            Please allow Camera Access for this site in your browser settings
          </Paragraph>
          <Button onClick={onClickClearError}>Try again</Button>
        </>
      ) : cameraContext.cameraImage && !cameraContext.imageConfirmed ? (
        <ButtonContainer>
          <RetakeButton onClick={onClickRetake}>
            <SmallButtonIcon icon={faTrashAlt} />
            Try Again
          </RetakeButton>
          <ContinueButton onClick={onClickContinue}>
            <SmallButtonIcon icon={faCheck} />
            Continue
          </ContinueButton>
        </ButtonContainer>
      ) : (
        <>
          <Paragraph align={"center"}>
            Take a clear picture of your window
          </Paragraph>
          <Button onClick={onClickCapture} width={"auto"}>
            <CameraIcon icon={faCamera} />
          </Button>
        </>
      )}
    </FlexColumn>
  );
});

export default CameraCapture;
