import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import ProductContext from "../contexts/ProductContext";
import UserContext from "../contexts/UserContext";
import { isEmailValid } from "../lib/validation";
import {
  Button,
  Flex1,
  FlexColumn,
  Label,
  TextArea,
  TextInput,
} from "./base-components";

const Container = styled.div`
  max-width: 540px;
  margin: 10px auto;
  padding: 0 10px;
  text-align: center;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  padding: 5px 0;
`;

const ContactDetailsForm = observer(() => {
  const userContext = useContext(UserContext);
  const productContext = useContext(ProductContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [postcode, setPostcode] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    if (userContext.emailAddress) {
      setEmail(userContext.emailAddress);
    }
  }, [userContext.emailAddress]);

  const isFormValid = () => {
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      phone.trim() !== "" &&
      postcode.trim().length >= 4 &&
      isEmailValid(email)
    );
  };

  const onSubmit = async () => {
    const variationIds: number[][] = [];
    const extrasIds: number[][] = [];
    for (let i = 0; i < productContext.numWindows; i++) {
      const variations = productContext.getProductVariationSelection(i);

      const _variationIds = variations.map((item) => {
        return item.id;
      });
      variationIds.push(_variationIds);

      const _extrasIds = [...productContext.getProductExtrasSelection(i)];
      extrasIds.push(_extrasIds);
    }

    await userContext.submitQuoteRequest({
      num_windows: productContext.numWindows,
      variation_ids: variationIds,
      extras_ids: extrasIds,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      email: email,
      postcode: postcode,
      comments: comments,
    });

    userContext.setLeadContactSubmitted = true;
  };

  return (
    <Container>
      <FlexColumn flex={1}>
        <Flex1>
          <InputWrapper>
            <Label>First Name *</Label>
            <TextInput
              placeholder={"John"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Last Name *</Label>
            <TextInput
              placeholder={"Smith"}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Phone Number *</Label>
            <TextInput
              placeholder={"+1234 567 89"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Email *</Label>
            <TextInput
              placeholder={"john.smith@gmail.com"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Postcode *</Label>
            <TextInput
              type={"number"}
              placeholder={"1234"}
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Extra Comments</Label>
            <TextArea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </InputWrapper>
        </Flex1>
      </FlexColumn>
      <FlexColumn flex={0}>
        <Button disabled={!isFormValid()} onClick={onSubmit}>
          {userContext.appSettings.dimensions_submit_btn_text}
        </Button>
      </FlexColumn>
    </Container>
  );
});

export default ContactDetailsForm;
