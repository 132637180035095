import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import ProductContext from "../contexts/ProductContext";
import { SHUTTERS_CATEGORY_ID } from "../resources/constants";
import { color } from "../resources/styles";
import { Category, Product } from "../types";
import {
  Button,
  Flex1,
  FlexRow,
  ParagraphLight,
  TitleSmall,
} from "./base-components";
import ProductVariationSelector from "./ProductVariationSelector";
import ShuttersWarningModal from "./ShuttersWarningModal";
import SliderTile from "./SliderTile";

const BackButton = styled(Button)`
  flex: 0;
  padding: 10px;
  margin: -5px 0 0 0;
  background-color: transparent;
`;

const BackIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: ${color.blue};
`;

const SlideContainer = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

type Props = {
  windowIndex: number;
  parentCategory: Category | null;
  visible: boolean;
  onClickBack?: () => void;
};

const CategorySelector = observer((props: Props) => {
  const productContext = useContext(ProductContext);

  const [selectedChildId, setSelectedChildId] = useState(-1);
  const [selectedChildType, setSelectedChildType] = useState<
    "category" | "product"
  >("category");
  const [isChildVisible, setIsChildVisible] = useState(false);

  const [showShuttersWarning, setShowShuttersWarning] = useState(false);

  useEffect(() => {
    if (
      props.visible &&
      props.parentCategory?.id === SHUTTERS_CATEGORY_ID &&
      !productContext.activeWindowWidth &&
      !showShuttersWarning
    ) {
      setShowShuttersWarning(true);
    } else {
      setShowShuttersWarning(false);
    }
  }, [props.visible]);

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const onClickCategory = (category: Category) => {
    setSelectedChildId(category.id);
    setIsChildVisible(true);
    productContext.setCurrentCategorySelection(props.windowIndex, category);
  };

  const onClickProduct = (product: Product) => {
    productContext.setProductSelection(props.windowIndex, product);
    setSelectedChildId(product.id);
    setIsChildVisible(true);
    setSelectedChildType("product");
    // setTimeout(function () {
    //   productContext.preloadProductVariations(props.windowIndex, product);
    // }, 500);
  };

  const onChildClickBack = () => {
    setIsChildVisible(false);
    setSelectedChildType("category");
  };

  const getTitle = (): string => {
    if (props.parentCategory === null) {
      return "Select a category";
    } else {
      let title = props.parentCategory.title;
      if (props.parentCategory.parent_category !== null) {
        title = props.parentCategory.parent_category.title + " - " + title;
      }
      return title;
    }
  };

  const isCategoryActive = (category: Category) => {
    // An item is selected if
    if (props.parentCategory === null) {
      return (
        productContext.getCurrentCategorySelection(props.windowIndex) ===
        category.id
      );
    }

    const productSelection = productContext.getProductSelection(
      props.windowIndex
    );
    if (productSelection && productSelection.product) {
      return productSelection.product.category_path.indexOf(category.id) > -1;
    }
    return false;
  };

  const isCategoryVisible = (category: Category) => {
    return (
      isChildVisible &&
      selectedChildType === "category" &&
      selectedChildId === category.id
    );
  };

  const isProductActive = (product: Product) => {
    const productSelection = productContext.getProductSelection(
      props.windowIndex
    );
    if (productSelection && productSelection.product) {
      return productSelection.product.id === product.id;
    }
    return false;
  };

  const isProductVisible = (product: Product) => {
    return (
      isChildVisible &&
      selectedChildType === "product" &&
      selectedChildId === product.id
    );
  };

  const getChildCategories = () => {
    return productContext.getCategories(
      props.parentCategory ? props.parentCategory.id : null
    );
  };

  const getChildProducts = () => {
    if (props.parentCategory) {
      return productContext.getProducts(props.parentCategory.id);
    }
    return [];
  };

  return (
    <div
      style={{
        display: props.visible ? "block" : "none",
      }}
    >
      <div
        style={{
          display: props.visible && !isChildVisible ? "block" : "none",
        }}
      >
        {showShuttersWarning && <ShuttersWarningModal />}
        <FlexRow justify={"center"} align={"center"}>
          {props.parentCategory !== null && (
            <BackButton onClick={onClickBack}>
              <BackIcon icon={faChevronLeft} />
            </BackButton>
          )}
          <Flex1>
            <TitleSmall style={{ marginTop: "5px", paddingRight: "30px" }}>
              {getTitle()}
            </TitleSmall>
          </Flex1>
        </FlexRow>
        {getChildCategories().length > 0 && (
          <SlideContainer>
            {getChildCategories().map((category) => {
              return (
                <SliderTile
                  key={`category-${category.id}`}
                  title={category.title}
                  image={category.image}
                  active={isCategoryActive(category)}
                  onClick={() => onClickCategory(category)}
                />
              );
            })}
          </SlideContainer>
        )}

        {getChildProducts().length > 0 && (
          <SlideContainer>
            {getChildProducts().map((product) => {
              return (
                <SliderTile
                  key={`product-${product.id}`}
                  title={product.title}
                  image={product.image}
                  active={isProductActive(product)}
                  onClick={() => onClickProduct(product)}
                />
              );
            })}
          </SlideContainer>
        )}
      </div>
      <div>
        {getChildCategories().map((category) => {
          return (
            <CategorySelector
              key={`category-selector-${category.id}`}
              windowIndex={props.windowIndex}
              parentCategory={category}
              visible={isCategoryVisible(category)}
              onClickBack={onChildClickBack}
            />
          );
        })}
      </div>

      {getChildProducts().map((product) => {
        return (
          <ProductVariationSelector
            windowIndex={props.windowIndex}
            visible={isProductVisible(product)}
            product={product}
            key={`product-vars-${product.id}`}
            onClickBack={onChildClickBack}
          />
        );
      })}

      {getChildCategories().length === 0 && getChildProducts().length === 0 && (
        <div>
          <ParagraphLight align={"center"}>
            This category does not contain any products.
            <br />
            This is a development message.
            <br />
            This category will be hidden in production.
          </ParagraphLight>
        </div>
      )}
    </div>
  );
});

export default CategorySelector;
