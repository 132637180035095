import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import UserContext from "../contexts/UserContext";
import {
  Button,
  ButtonAlt,
  ModalBackdrop,
  ModalContainer,
} from "./base-components";

const GetAQuoteModal = observer(() => {
  const userContext = useContext(UserContext);

  const [visible, setVisible] = useState(true);
  const [opacity, setOpacity] = useState(0);

  // Fade in on load
  useEffect(() => {
    setTimeout(function () {
      setOpacity(1);
    }, 100);
  }, []);

  const hideModal = () => {
    setOpacity(0);
    setTimeout(function () {
      setVisible(false);
      userContext.setGetAQuoteModalVisible = false;
    }, 500);
  };

  const onPressOk = () => {
    hideModal();
    userContext.setSubmittingLead = true;
  };

  return (
    <ModalBackdrop
      style={{ display: visible ? "block" : "none", opacity: opacity }}
    >
      <ModalContainer style={{ opacity: 1 }}>
        <Button onClick={onPressOk}>
          {userContext.appSettings.quote_modal_confirm_btn_text}
        </Button>
        <ButtonAlt style={{ fontSize: "0.8rem" }} onClick={hideModal}>
          {userContext.appSettings.quote_modal_cancel_btn_text}
        </ButtonAlt>
      </ModalContainer>
    </ModalBackdrop>
  );
});

export default GetAQuoteModal;
