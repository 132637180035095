import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as BaseLink } from "react-router-dom";
import styled from "styled-components";

import { color } from "../resources/styles";

// Flex Layout Styles
export const FullscreenView = styled.div`
  width: 100vw;
  height: 100%;
`;

export const FlexContainer = styled.div<{
  align?: string;
  justify?: string;
  flex?: number;
  direction?: string;
}>`
  flex: ${(props) => props.flex ?? "initial"};
  display: flex;
  align-items: ${(props) => props.align ?? "initial"};
  justify-content: ${(props) => props.justify ?? "initial"};
`;

export const FlexRow = styled(FlexContainer)`
  flex-direction: row;
`;

export const FlexColumn = styled(FlexContainer)`
  flex-direction: column;
`;

export const Container = styled(FlexColumn)`
  padding: 30px;
  min-height: 100%;
`;

export const Flex0 = styled.div`
  flex: 0;
`;

export const Flex1 = styled.div`
  flex: 1;
`;

// Text Styles
export const Title = styled.h1<{ align?: string }>`
  font-weight: 300;
  font-size: 1.8rem;
  text-transform: uppercase;
  text-align: ${(props) => props.align ?? "initial"};
  color: ${color.blue};
`;

export const TitleSmall = styled(Title)`
  font-size: 1.2rem;
`;

export const Paragraph = styled.p<{
  align?: string;
  margin?: string;
  padding?: string;
  color?: string;
  size?: string;
  transform?: string;
}>`
  font-weight: normal;
  font-size: ${(props) => props.size ?? "1rem"};
  margin: ${(props) => props.margin ?? "0 0 1rem 0"};
  padding: ${(props) => props.padding ?? "0"};
  text-align: ${(props) => props.align ?? "initial"};
  text-transform: ${(props) => props.transform ?? "none"};
  color: ${(props) => props.color ?? "initial"};
`;

export const ParagraphLight = styled(Paragraph)`
  font-weight: 300;
`;

export const ParagraphBold = styled(Paragraph)`
  font-weight: bold;
`;

export const Link = styled.a<{
  align?: string;
  transform?: string;
  weight?: string;
}>`
  color: ${color.orange};
  text-decoration: none;
  text-align: ${(props) => props.align ?? "initial"};
  text-transform: ${(props) => props.transform ?? "initial"};
  font-weight: ${(props) => props.weight ?? "normal"};

  &:hover {
    color: ${color.orange};
  }
`;

export const RouterLink = styled(BaseLink)<{
  align?: string;
  transform?: string;
  weight?: string;
}>`
  color: ${color.orange};
  text-decoration: none;
  text-align: ${(props) => props.align ?? "initial"};
  text-transform: ${(props) => props.transform ?? "initial"};
  font-weight: ${(props) => props.weight ?? "normal"};

  &:hover {
    color: ${color.orange};
  }
`;

// Form components
export const Label = styled.label`
  font-weight: bold;
  text-align: left;
  color: ${color.black};
  text-transform: uppercase;
  font-size: 0.8rem;
  display: block;
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextInput = styled.input`
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border-color: ${color.lightgrey};
  border-width: 1px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 5px 0 10px 0;
  border-style: solid;
`;

export const TextArea = styled.textarea`
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border-color: ${color.lightgrey};
  border-width: 1px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 5px 0 10px 0;
  border-style: solid;
  resize: vertical;
  min-height: 50px;
`;

export const Button = styled.button<{ width?: string }>`
  position: relative;
  padding: 15px;
  border-radius: 50px;
  width: ${(props) => props.width ?? "100%"};
  margin: 0 -5px 10px -5px;
  background-color: ${color.orange};
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  color: ${color.white};
  font-size: 1.1rem;
  transition: color 0.3s, background-color 0.3s;

  &:disabled {
    color: ${color.darkgrey};
    background-color: ${color.lightgrey};
  }
`;

export const ButtonAlt = styled(Button)`
  background-color: ${color.lightgrey};
  color: ${color.orange};
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.8rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: calc(100% + 10px);
  margin: 0 -5px;
`;

export const ActionButton = styled(Button)`
  padding: 10px;
  width: 100%;
  flex: 1;
  margin: 0 5px;
`;
export const ActionButtonAlt = styled(ButtonAlt)`
  padding: 10px;
  width: 100%;
  flex: 1;
  margin: 0 5px;
`;

export const CenteredView = styled.div`
  max-width: 420px;
  margin: auto;
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.6s;
  opacity: 0;
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  max-width: 320px;
  border-radius: 20px;
  left: 15px;
  right: 15px;
  margin: auto;
  background-color: #fff;
  padding: 15px 15px 0;
  z-index: 1000;
  box-shadow: 1px 0 10px #aaa;

  transition: opacity 0.3s;
  opacity: 0;

  @media (min-aspect-ratio: 8/5) {
    top: 50%;
  }
`;

export const ModalContents = styled.div`
  position: relative;
  padding: 0 8px 8px 8px;
`;
