export const color = {
  grey: "rgb(112, 115, 114)",
  blue: "rgb(0, 46, 95)",
  yellow: "rgb(225, 242, 45)",
  orange: "rgb(210, 89, 45)",
  white: "#FFF",
  black: "#000",
  lightgrey: "#EEE",
  darkgrey: "#AAA",
  transparent: "transparent",
};
