import React from "react";
import styled from "styled-components";

import logo from "../resources/img/logo@2x.png";
const LogoContainer = styled.div`
  margin: auto;
  width: 100%;
`;

const LogoImg = styled.img<{ animated?: boolean }>`
  margin: auto;
  width: 100%;
  height: auto;
  max-width: 320px;
  display: block;

  opacity: ${(props) => (props.animated ? 0 : 1)};
  animation-name: ${(props) =>
    props.animated ? "fadeinslidedown" : "initial"};
  animation-delay: 3.5s;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @media (min-aspect-ratio: 8/5) {
    max-width: 200px;
  }
`;

export default function Logo(props: { animated?: boolean }): JSX.Element {
  return (
    <LogoContainer>
      <h1 style={{ display: "none" }}>Apollo HomeView</h1>
      <LogoImg src={logo} animated={props.animated} alt="Apollo HomeView" />
    </LogoContainer>
  );
}
