import React from "react";
import styled from "styled-components";

import { color } from "../resources/styles";
import { Label } from "./base-components";

type Props = {
  title: string;
  image: string;
  onClick: () => void;
  active?: boolean;
  size?: "small";
};

const Outer = styled.div<{
  size?: "small";
}>`
  display: inline-block;
  width: ${(props) => (props.size === "small" ? "120px" : "150px")};
  margin-right: 20px;

  @media (min-aspect-ratio: 8/5) {
    width: ${(props) => (props.size === "small" ? "80px" : "100px")};
  }
`;

const ImageContainer = styled.div<{
  active?: boolean;
}>`
  padding: 1px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid
    ${(props) => (props.active ? color.blue : color.transparent)};
  margin-top: 4px;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 9px;
`;

const SliderTile = (props: Props): JSX.Element => {
  return (
    <Outer onClick={props.onClick} size={props.size}>
      <Label>{props.title}</Label>
      <ImageContainer active={props.active}>
        <Image
          src={`${process.env.REACT_APP_BACKEND_ENDPOINT}${props.image}`}
        />
      </ImageContainer>
    </Outer>
  );
};

export default SliderTile;
