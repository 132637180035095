import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import {
  Button,
  ButtonAlt,
  ModalBackdrop,
  ModalContainer,
  Paragraph,
  TextInput,
  TitleSmall,
} from "./base-components";

const OTPModal = observer(
  (props: { onHide: () => void; onSubmit: (code: string) => void }) => {
    const [visible, setVisible] = useState(true);
    const [opacity, setOpacity] = useState(0);
    const [code, setCode] = useState("");

    // Fade in on load
    useEffect(() => {
      setTimeout(function () {
        setOpacity(1);
      }, 100);
    }, []);

    const hideModal = () => {
      setOpacity(0);
      setTimeout(function () {
        setVisible(false);
        props.onHide();
      }, 500);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onPressOk();
      }
    };

    const onPressOk = () => {
      props.onSubmit(code);
      hideModal();
    };

    const isFormValid = () => {
      return code.trim().length === 6;
    };

    return (
      <ModalBackdrop
        style={{ display: visible ? "block" : "none", opacity: opacity }}
      >
        <ModalContainer style={{ opacity: 1 }}>
          <TitleSmall align={"center"}>One-time code required</TitleSmall>
          <Paragraph align={"center"}>
            You have been emailed a one-time code. Please enter it below to
            continue:
          </Paragraph>

          <TextInput
            placeholder={"123456"}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            onKeyDown={handleKeyDown}
            style={{ textAlign: "center" }}
          />

          <Button
            style={{ fontSize: "0.8rem" }}
            onClick={onPressOk}
            disabled={!isFormValid()}
          >
            Continue
          </Button>
          <ButtonAlt style={{ fontSize: "0.8rem" }} onClick={hideModal}>
            Go Back
          </ButtonAlt>
        </ModalContainer>
      </ModalBackdrop>
    );
  }
);

export default OTPModal;
