import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { color } from "../resources/styles";
import { FullscreenView, Title } from "./base-components";

const Container = styled(FullscreenView)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${color.white};
`;

const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: auto;
  text-align: center;
`;

const TitleText = styled(Title)`
  color: ${color.orange};
`;

const LoaderIcon = styled(FontAwesomeIcon)`
  font-size: 4rem;
  color: ${color.orange};
`;

const LoaderView = (props: { message?: string }): JSX.Element => {
  return (
    <Container>
      <Centered>
        <LoaderIcon icon={faSpinner} pulse />
        {props.message ? (
          <TitleText align={"center"}>{props.message}</TitleText>
        ) : (
          <TitleText align={"center"}>Loading. Please wait...</TitleText>
        )}
      </Centered>
    </Container>
  );
};

export default LoaderView;
