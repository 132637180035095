import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import ProductContext from "../contexts/ProductContext";
import UserContext from "../contexts/UserContext";
import { DEFAULT_WINDOW_WIDTH } from "../resources/constants";
import { color } from "../resources/styles";
import {
  ActionButton,
  ActionButtonAlt,
  ButtonContainer,
  Label,
  Link,
  Paragraph,
  TextInput,
} from "./base-components";
import WindowMeasureVideoOverlay from "./WindowMeasureVideoOverlay";

const InputContainer = styled.div`
  display: flex;
`;

const Input = styled(TextInput)`
  flex: 1;
  justify-content: stretch;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
`;

const InputSuffix = styled.div`
  flex: 0;
  padding: 0 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: ${color.lightgrey};
  border-width: 1px;
  border-style: solid;
  margin: 5px 0 10px 0;
  color: ${color.grey};
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

const WindowCapture = observer(() => {
  const userContext = useContext(UserContext);
  const productContext = useContext(ProductContext);

  const [measureVideoShown, setMeasureVideoShown] = useState(false);

  const onClickAddWindow = () => {
    productContext.addWindow();
  };

  const onClickConfirmWindows = () => {
    productContext.setHasConfirmedWindows = true;
    // productContext.preloadTopLevelProductsAllWindows();
  };

  return (
    <>
      {productContext.activeWindowIndex === 1 ? (
        <Paragraph margin={"5px 0 1rem 0"} padding={"0 30px 0 0"}>
          Drag the box and corners to cover the second window
        </Paragraph>
      ) : productContext.activeWindowIndex === 2 ? (
        <Paragraph margin={"5px 0 1rem 0"} padding={"0 30px 0 0"}>
          Drag the box and corners to cover the third window
        </Paragraph>
      ) : (
        <Paragraph margin={"5px 0 1rem 0"} padding={"0 30px 0 0"}>
          Drag the box and corners to cover the window
        </Paragraph>
      )}

      <Label>Width of Window</Label>
      <InputContainer>
        <Input
          placeholder={DEFAULT_WINDOW_WIDTH}
          type={"number"}
          value={productContext.activeWindowWidth}
          onChange={(e) =>
            (productContext.setActiveWindowWidth = e.target.value)
          }
        />
        <InputSuffix>millimetres</InputSuffix>
      </InputContainer>
      <Paragraph
        align={"left"}
        margin={"0 0 10px 0"}
        color={
          productContext.hasInteractedWithWindow ? color.orange : color.grey
        }
        size={"0.8rem"}
      >
        Enter the correct width of your window for a better visualisation
        experience.
      </Paragraph>
      <Paragraph align={"center"} margin={"0 0 15px 0"} size={"0.9rem"}>
        {userContext.appSettings.window_measure_video && (
          <Link
            style={{ textDecoration: "underline" }}
            href={"#"}
            weight={"bold"}
            onClick={() => setMeasureVideoShown(true)}
          >
            Watch instructional video
          </Link>
        )}
      </Paragraph>

      <ButtonContainer>
        {productContext.canAddWindow && (
          <ActionButtonAlt
            disabled={!productContext.hasInteractedWithWindow}
            onClick={onClickAddWindow}
          >
            Add Window
          </ActionButtonAlt>
        )}
        <ActionButton
          disabled={!productContext.hasInteractedWithWindow}
          onClick={onClickConfirmWindows}
        >
          View Blinds
        </ActionButton>
      </ButtonContainer>
      {productContext.canAddWindow && (
        <Paragraph
          align={"center"}
          margin={"10px 0 0 0"}
          color={
            productContext.hasInteractedWithWindow ? color.orange : color.grey
          }
          size={"0.8rem"}
        >
          You can add more windows later
        </Paragraph>
      )}
      {measureVideoShown && (
        <WindowMeasureVideoOverlay
          onClose={() => setMeasureVideoShown(false)}
        />
      )}
    </>
  );
});

export default WindowCapture;
