import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import UserContext from "../contexts/UserContext";
import { color } from "../resources/styles";
import {
  Button,
  ModalContainer,
  ModalContents,
  Paragraph,
} from "./base-components";

const TitleParagraph = styled(Paragraph)`
  font-size: 1.1rem;
  margin-bottom: 20px;
  text-align: center;
`;

const TipParagraph = styled(Paragraph)`
  position: relative;
  padding-left: 22px;
  font-size: 0.9rem;
`;

const TickIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8rem;
  color: ${color.orange};
`;

const CameraTipsModal = observer(() => {
  const userContext = useContext(UserContext);

  const [visible, setVisible] = useState(true);
  const [opacity, setOpacity] = useState(0);

  // Fade in on load
  useEffect(() => {
    setTimeout(function () {
      setOpacity(1);
    }, 500);
  }, []);

  const onPressOk = () => {
    setOpacity(0);
    setTimeout(function () {
      setVisible(false);
      userContext.setDismissedCameraModal = true;
    }, 1000);
  };

  return (
    <ModalContainer
      style={{ display: visible ? "block" : "none", opacity: opacity }}
    >
      <ModalContents>
        <TitleParagraph>
          {"Here's a couple of tips to take a good picture:"}
        </TitleParagraph>
        <TipParagraph>
          <TickIcon icon={faCheck} />
          Make sure there is good lighting
        </TipParagraph>
        <TipParagraph>
          <TickIcon icon={faCheck} />
          Keep your phone straight on & don’t tilt
        </TipParagraph>
        <TipParagraph>
          <TickIcon icon={faCheck} />
          Position yourself so the window only takes up about 80% of the picture
        </TipParagraph>
      </ModalContents>
      <Button onClick={onPressOk}>Got It</Button>
    </ModalContainer>
  );
});

export default CameraTipsModal;
