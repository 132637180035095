import { faCheckCircle, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { color } from "../resources/styles";
import { Label } from "./base-components";

type Props = {
  title: string;
  active?: boolean;
  isExtra?: boolean;
  onClick: () => void;
};

const Outer = styled.div<{
  active?: boolean;
  isExtra?: boolean;
}>`
  // flex: 0;
  display: inline-block;
  padding: 10px 5px;
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => {
    if (!props.isExtra) {
      return props.active ? color.darkgrey : color.lightgrey;
    } else {
      return props.active ? color.blue : color.lightgrey;
    }
  }};
  background-color: ${(props) => {
    if (props.isExtra) {
      return color.lightgrey;
    } else {
      return props.active ? color.darkgrey : color.lightgrey;
    }
  }};
  @media (min-aspect-ratio: 8/5) {
    padding: 5px 5px;
  }
`;
const Inner = styled.div`
  display: flex;
`;
const CircleIcon = styled(FontAwesomeIcon)`
  font-size: 0.8rem;
  margin-left: 5px;
`;
const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 0.8rem;
  margin-left: 5px;
  color: ${color.blue};
`;

const SliderPill = (props: Props): JSX.Element => {
  return (
    <Outer
      onClick={props.onClick}
      active={props.active}
      isExtra={props.isExtra}
    >
      <Inner>
        {!props.active && props.isExtra && <CircleIcon icon={faDotCircle} />}
        {props.active && props.isExtra && <CheckIcon icon={faCheckCircle} />}
        <Label>{props.title}</Label>
      </Inner>
    </Outer>
  );
};

export default SliderPill;
