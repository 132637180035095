import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  Button,
  Container,
  Flex0,
  Flex1,
  Link,
  Paragraph,
  RouterLink,
  Title,
  TitleSmall,
} from "../components/base-components";
import Logo from "../components/Logo";

const Section = styled.div`
  margin-bottom: 30px;
`;

const BackButton = styled(Button)`
  max-width: 540px;
  margin: 60px auto 60px auto;
  display: block;
`;

const TermsScreen = (): JSX.Element => {
  const history = useHistory();
  const onClickBack = () => {
    history.goBack();
  };
  return (
    <Container>
      <Flex0>
        <Section>
          <Logo />
        </Section>
        <Section>
          <Title align={"center"}>Terms of Use</Title>
        </Section>
        <Paragraph>
          Apollo Window Blinds and its affiliates provide their services to you
          subject to the following conditions. When you visit our website you
          automatically accept these conditions. Please read below our Term of
          Use.
        </Paragraph>
      </Flex0>

      <Flex1>
        <Section>
          <TitleSmall>Privacy Policy</TitleSmall>
          <Paragraph>
            Please refer to the{" "}
            <RouterLink to={"/privacy"}>Privacy Policy</RouterLink> page.
          </Paragraph>
        </Section>
        <Section>
          <TitleSmall>Copyright</TitleSmall>
          <Paragraph>
            All content included on this site, such as text, photographs, logos,
            graphics, button icons, data compilations and software, and the
            selection, arrangement and layout thereof are the property of Apollo
            Window Blinds or licensed to Apollo Window Blinds and as such, are
            protected by Australian and international copyright laws.
          </Paragraph>
        </Section>
        <Section>
          <TitleSmall>Prohibitions</TitleSmall>
          <Paragraph>
            Attempts to upload information or change information on the Apollo
            Window Blinds Web Site are strictly prohibited unless specifically
            authorized in writing by Apollo Window Blinds. This prohibition does
            not apply to a user providing information through an online form
            created by Apollo Window Blinds for that purpose.
          </Paragraph>
        </Section>
        <Section>
          <TitleSmall>Third Party Links</TitleSmall>
          <Paragraph>
            You are granted a limited, revocable, and nonexclusive right to
            create a hyperlink to the home page of the Apollo Window Blinds Web
            Site as long as the link does not portray Apollo Window Blinds or
            its affiliates, in a false, misleading, derogatory, or otherwise
            offensive manner.
          </Paragraph>
        </Section>
        <Section>
          <TitleSmall>Hypertext Links</TitleSmall>
          <Paragraph>
            The Apollo HomeView Web Site may contain hypertext links to other
            sites on the internet. However, Apollo Window Blinds is not
            responsible for any information contained in other internet sites,
            nor is it liable for any inaccurate, defamatory, offensive, or
            illegal materials found on other Internet websites.
          </Paragraph>
        </Section>
        <Section>
          <TitleSmall>
            Disclaimer Of Warranties And Limitation Of Liability.
          </TitleSmall>
          <Paragraph>
            Apollo Window Blinds shall not be liable for any damage, loss or
            liability of what so ever nature arising from the use or inability
            to use this Web Site or the services or content provided from and
            through this web site. Furthermore, Apollo Window Blinds makes no
            representations or warranties, implied or otherwise, that, amongst
            others, the content and technology available from this web site are
            free from errors or omissions or that the service will be 100%
            uninterrupted and error free.
          </Paragraph>
          <Paragraph>
            This web site is supplied on an “as is” basis and has not been
            compiled or supplied to meet the user’s individual requirements. It
            is the sole responsibility of the user to satisfy itself prior to
            entering into this agreement with Apollo Window Blinds that the
            service available from and through this web site will meet the
            user’s individual requirements and be compatible with the user’s
            hardware and/or software.
          </Paragraph>
          <Paragraph>
            Information, ideas and opinions expressed on this site should not be
            regarded as professional advice or as the official opinion of Apollo
            Window Blinds, and users are encouraged to consult professional
            advice before taking any course of action related to information,
            ideas or opinions expressed on this site.
          </Paragraph>
        </Section>
        <Section>
          <TitleSmall>Governing Law</TitleSmall>
          <Paragraph>
            This web site is operated from Australia, and thus the Australian
            Law governs the use or liability to use this web site and these
            terms and conditions.
          </Paragraph>
        </Section>
        <Section>
          <TitleSmall>Terms & Conditions</TitleSmall>
          <Paragraph>
            Download{" "}
            <Link
              href={
                "https://apolloblinds.com.au/wp-content/uploads/2021/05/ApolloTermsAndConditions-20210305.pdf"
              }
              target={"_blank"}
            >
              Terms &amp; Conditions
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <BackButton onClick={onClickBack}>Go Back</BackButton>
        </Section>
      </Flex1>
    </Container>
  );
};

export default TermsScreen;
