import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Button,
  ModalBackdrop,
  ModalContainer,
  TitleSmall,
} from "./base-components";

const Title = styled(TitleSmall)`
  margin-bottom: 26px;
`;

const LoginFailureModal = observer((props: { onHide: () => void }) => {
  const [visible, setVisible] = useState(true);
  const [opacity, setOpacity] = useState(0);

  // Fade in on load
  useEffect(() => {
    setTimeout(function () {
      setOpacity(1);
    }, 100);
  }, []);

  const hideModal = () => {
    setOpacity(0);
    setTimeout(function () {
      setVisible(false);
      props.onHide();
    }, 500);
  };

  return (
    <ModalBackdrop
      style={{ display: visible ? "block" : "none", opacity: opacity }}
    >
      <ModalContainer style={{ opacity: 1 }}>
        <Title align={"center"}>
          Login failed!
          <br />
          Please try again
        </Title>

        <Button style={{ fontSize: "0.8rem" }} onClick={hideModal}>
          Continue
        </Button>
      </ModalContainer>
    </ModalBackdrop>
  );
});

export default LoginFailureModal;
