import axios from "axios";
import { cartesianProduct } from "cartesian-product-multiple-arrays";
import { makeAutoObservable, observable, runInAction } from "mobx";
import PromisePool from "native-promise-pool";
import { createContext } from "react";

import {
  DEFAULT_WINDOW_HEIGHT,
  DEFAULT_WINDOW_WIDTH,
} from "../resources/constants";
import {
  Category,
  Product,
  ProductSelection,
  ProductVariation,
  VariationType,
} from "../types";

const MAX_WINDOWS = 3;

class ProductStore {
  hasInteractedWithWindow = false;
  hasConfirmedWindows = false;
  numWindows = 0;
  activeWindowIndex = -1;
  activeWindowWidth = "";

  windowWidths: string[] = observable.array([]);
  windowHeights: string[] = observable.array([]);
  preloadedUrls: string[] = [];

  categories: Category[] = [];
  products: Product[] = [];
  variations: ProductVariation[] = [];

  categoryMap: Map<number, Category> = new Map<number, Category>();
  productMap: Map<number, Product> = new Map<number, Product>();
  variationMap: Map<number, ProductVariation> = new Map<
    number,
    ProductVariation
  >();
  variationTypeMap: Map<number, VariationType> = new Map<
    number,
    VariationType
  >();

  // We want to remember the user's selections within each Top-Level-Category
  // so that they can switch between top-level cats and it remembers their previous selection
  // This needs to be stored on a per-window basis
  productSelectionHistory: Map<number, ProductSelection>[] = [];

  // This is a pointer to the current selected top-level-cat per window
  categorySelections: number[] = [];

  constructor() {
    makeAutoObservable(this);
    observable.array(this.productSelectionHistory, { deep: true });
  }

  reset() {
    this.hasConfirmedWindows = false;
    this.hasInteractedWithWindow = false;
    this.numWindows = 0;
    this.activeWindowIndex = -1;
    this.activeWindowWidth = "";
    this.windowWidths = [];
    this.windowHeights = [];
    this.productSelectionHistory = [];
    this.categorySelections = [];
  }

  get getAllLoaded() {
    return (
      this.categories.length > 0 &&
      this.products.length > 0 &&
      this.variations.length > 0
    );
  }

  getCategories(parentCategoryId: number | null) {
    return this.categories.filter((item) => {
      return item.parent_id === parentCategoryId;
    });
  }

  getProducts(parentCategoryId: number | null) {
    return this.products.filter((item) => {
      return item.category_id === parentCategoryId;
    });
  }

  getVariations(variationTypeId: number, productId: number) {
    return this.variations.filter((item) => {
      return (
        item.variation_type_id === variationTypeId &&
        item.product_id === productId
      );
    });
  }

  getCurrentCategorySelection(windowIndex: number) {
    if (this.categorySelections.length < windowIndex - 1) {
      return null;
    }
    return this.categorySelections[windowIndex];
  }

  setCurrentCategorySelection(windowIndex: number, category: Category) {
    this.categorySelections[windowIndex] = category.top_level_category_id;
    // const defaultProductSelection = this.getDefaultProductSelection(category);
    // this.productSelectionHistory[windowIndex].set(
    //   category.top_level_category_id,
    //   defaultProductSelection
    // );
  }

  getProductSelection(windowIndex: number) {
    if (
      this.productSelectionHistory.length < windowIndex - 1 ||
      !this.productSelectionHistory[windowIndex]
    ) {
      return null;
    }
    const currentSelectedId = this.categorySelections[windowIndex];
    if (currentSelectedId === -1) {
      return null;
    }

    return this.productSelectionHistory[windowIndex].get(currentSelectedId);
  }

  setProductSelection(windowIndex: number, product: Product) {
    const category = this.categoryMap.get(product.category_id);
    if (category) {
      let selection = this.productSelectionHistory[windowIndex].get(
        category.top_level_category_id
      );

      if (
        selection &&
        selection.product &&
        selection.product.id === product.id
      ) {
        return;
      }

      const variationSelections =
        this.getDefaultProductVariationSelection(product);

      if (!selection) {
        selection = {
          product,
          category,
          variationSelections,
          extras: [],
        };
      } else {
        selection.product = product;
        selection.category = category;
        selection.variationSelections = variationSelections;
      }
      this.productSelectionHistory[windowIndex].set(
        category.top_level_category_id,
        selection
      );
    }
  }

  setProductExtrasSelection(
    windowIndex: number,
    productId: number,
    extras: number[],
    syncWindows?: boolean
  ) {
    const product = this.productMap.get(productId);
    if (!product) {
      return;
    }
    const category = this.categoryMap.get(product.category_id);
    if (!category) {
      return;
    }
    const selection = this.productSelectionHistory[windowIndex].get(
      category.top_level_category_id
    );
    if (!selection) {
      return;
    }
    const currentExtras = this.getProductExtrasSelection(windowIndex);

    selection.extras = extras;
    this.productSelectionHistory[windowIndex].set(
      category.top_level_category_id,
      selection
    );

    if (syncWindows) {
      const currentSelection = this.getProductVariationSelection(windowIndex);
      this.syncProductVariationSelection(
        windowIndex,
        currentSelection,
        currentExtras,
        null,
        extras,
        product.id
      );
    }
  }

  setProductVariationSelection(
    windowIndex: number,
    productVariation: ProductVariation,
    syncWindows?: boolean
  ) {
    const product = this.productMap.get(productVariation.product_id);
    if (!product) {
      return;
    }
    const category = this.categoryMap.get(product.category_id);
    if (!category) {
      return;
    }
    const selection = this.productSelectionHistory[windowIndex].get(
      category.top_level_category_id
    );
    if (!selection) {
      return;
    }

    const currentSelection = this.getProductVariationSelection(windowIndex);

    selection.variationSelections[productVariation.variation_type_id] =
      productVariation;
    this.productSelectionHistory[windowIndex].set(
      category.top_level_category_id,
      selection
    );

    if (syncWindows) {
      this.syncProductVariationSelection(
        windowIndex,
        currentSelection,
        selection.extras,
        productVariation,
        selection.extras,
        product.id
      );
    }
  }

  syncProductVariationSelection(
    sourceWindowIndex: number,
    currentVariations: ProductVariation[],
    currentExtras: number[],
    newSelection: ProductVariation | null,
    newExtras: number[] | null,
    productId: number
  ) {
    const currentIds = currentVariations.map((item) => item.id).sort();
    const currentExtrasIds = currentExtras.sort();
    for (let i = 0; i < this.numWindows; i++) {
      if (i === sourceWindowIndex) {
        continue;
      }

      const compareExtrasIds = this.getProductExtrasSelection(i).sort();

      const compareVariations = this.getProductVariationSelection(i);
      // Compare and sync extras changes
      const compareIds = compareVariations.map((item) => item.id).sort();
      if (
        currentIds.length === compareIds.length &&
        currentExtrasIds.length === compareExtrasIds.length
      ) {
        let allMatch = true;
        for (let x = 0; x < currentIds.length; x++) {
          if (currentIds[x] !== compareIds[x]) {
            allMatch = false;
            break;
          }
        }
        for (let x = 0; x < currentExtrasIds.length; x++) {
          if (currentExtrasIds[x] !== compareExtrasIds[x]) {
            allMatch = false;
            break;
          }
        }
        if (allMatch) {
          if (newSelection) {
            this.setProductVariationSelection(i, newSelection);
          }
          if (newExtras) {
            this.setProductExtrasSelection(i, productId, newExtras);
          }
        }
      }
    }
  }

  getProductVariationSelection(windowIndex: number): ProductVariation[] {
    const productSelection = this.getProductSelection(windowIndex);
    if (!productSelection) {
      return [];
    }

    return Object.keys(productSelection.variationSelections).map((k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return productSelection.variationSelections[k];
    });
  }

  getProductExtrasSelection(windowIndex: number): number[] {
    const productSelection = this.getProductSelection(windowIndex);
    if (!productSelection) {
      return [];
    }

    return productSelection.extras;
  }

  get allWindowsHaveProductSelections() {
    for (let i = 0; i < this.numWindows; i++) {
      const selection = this.getProductSelection(i);
      if (selection === null) {
        return false;
      }
    }

    return true;
  }

  getProductSelectionHash(windowIndex: number) {
    let hash = "";
    const history = this.getProductSelection(windowIndex);
    if (history) {
      for (const id of Object.keys(history.variationSelections)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hash += "v" + history.variationSelections[id].id;
      }
    }
    return hash;
  }

  generateDefaultProductSelectionHistory() {
    // For each Top-Level-Category, go a pick the default product and variants
    const productSelections = new Map<number, ProductSelection>();
    const topLevelCats = this.getCategories(null);
    for (const topLevelCategory of topLevelCats) {
      productSelections.set(
        topLevelCategory.id,
        this.getDefaultProductSelection(topLevelCategory)
      );
    }
    return productSelections;
  }

  getDefaultProductSelection(category: Category): ProductSelection {
    let childCategory = category;
    // Recursively find the deepest first child
    while (this.getCategories(childCategory.id).length) {
      childCategory = this.getCategories(childCategory.id)[0];
    }

    const products = this.getProducts(childCategory.id);
    const product = products.length ? products[0] : null;
    let selectedVariations: Record<number, ProductVariation> = {};
    if (product) {
      selectedVariations = this.getDefaultProductVariationSelection(product);
    }

    return {
      category: childCategory,
      product,
      variationSelections: selectedVariations,
      extras: [],
    };
  }

  getDefaultProductVariationSelection(product: Product) {
    const selectedVariations: Record<number, ProductVariation> = {};
    for (const variationType of product.variation_types) {
      const variations = this.getVariations(variationType.id, product.id);
      if (variations.length) {
        selectedVariations[variationType.id] = variations[0];
      }
    }

    return selectedVariations;
  }

  async loadCategories() {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/categories`
    );
    runInAction(() => {
      this.categoryMap.clear();
      for (const category of result.data) {
        this.categoryMap.set(category.id, category);
      }
      this.categories = result.data;
    });
  }

  async loadProducts() {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/products`
    );
    runInAction(() => {
      this.productMap.clear();
      for (const product of result.data) {
        this.productMap.set(product.id, product);
      }
      this.products = result.data;
    });
  }

  async loadVariations() {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/variations`
    );
    runInAction(() => {
      this.variations = result.data;
    });
    const images = [];
    for (const variation of result.data) {
      this.variationMap.set(variation.id, variation);
      if (variation.image) {
        images.push(variation.image);
      }
    }
    await this.preloadImages(images);
  }

  async loadAll() {
    await Promise.all([
      this.loadCategories(),
      this.loadProducts(),
      this.loadVariations(),
    ]);
  }

  /* WINDOW RELATED */
  addWindow() {
    const newHistory = this.generateDefaultProductSelectionHistory();
    this.productSelectionHistory.push(newHistory);
    this.categorySelections.push(-1);
    this.windowWidths.push("");
    this.windowHeights.push("");
    this.numWindows += 1;
    this.setActiveWindow = this.numWindows - 1;
  }

  get canAddWindow() {
    return this.numWindows < MAX_WINDOWS;
  }

  set setHasInteractedWithWindow(val: boolean) {
    this.hasInteractedWithWindow = val;
  }

  set setHasConfirmedWindows(val: boolean) {
    this.hasConfirmedWindows = val;
  }

  set setActiveWindow(val: number) {
    this.activeWindowIndex = val;
    this.activeWindowWidth = this.windowWidths[this.activeWindowIndex];
  }

  set setActiveWindowWidth(width: string) {
    this.setWindowWidth = { index: this.activeWindowIndex, width };
  }

  set setWindowWidth(values: { index: number; width: string }) {
    this.activeWindowWidth = values.width;
    this.windowWidths[values.index] = values.width;
  }
  set setWindowHeight(values: { index: number; height: string }) {
    this.windowHeights[values.index] = values.height;
  }

  /* IMAGE HELPERS */
  async preloadCategoryImages() {
    const imageUrls = this.categories
      .map((category) => {
        return category.image;
      })
      .filter((item) => {
        return !!item;
      });
    return this.preloadImages(imageUrls);
  }

  async preloadImages(imageUrls: string[]) {
    const pool = new PromisePool(6);
    const promises = imageUrls.map((imageUrl) => {
      return pool.open(() => this.preloadImage(imageUrl));
    });
    return Promise.all(promises);
  }

  async preloadImage(imageUrl: string) {
    if (imageUrl.indexOf("http") !== 0) {
      imageUrl = process.env.REACT_APP_BACKEND_ENDPOINT + imageUrl;
    }
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = resolve;
    });
  }

  async preloadTopLevelProductsDefaultWindowSize() {
    this.preloadTopLevelProducts(DEFAULT_WINDOW_WIDTH, DEFAULT_WINDOW_HEIGHT);
  }

  async preloadTopLevelProductsAllWindows() {
    for (let i = 0; i < this.numWindows; i++) {
      const windowWidth = this.windowWidths[i]
        ? this.windowWidths[i]
        : DEFAULT_WINDOW_WIDTH;
      const windowHeight = this.windowHeights[i]
        ? this.windowHeights[i]
        : DEFAULT_WINDOW_HEIGHT;
      this.preloadTopLevelProducts(windowWidth, windowHeight);
    }
  }
  async preloadTopLevelProducts(windowWidth: string, windowHeight: string) {
    // Download the default selection for every product
    const images = [];
    for (const product of this.products) {
      const variationIds = [];
      // const extrasIds: number[] = [];
      for (const variationType of product.variation_types) {
        let variationId = -1;
        for (const variation of product.product_variations) {
          if (
            variation.variation_type_id === variationType.id &&
            variationId === -1
          ) {
            variationId = variation.id;
            break;
          }
        }
        variationIds.push(variationId);
      }
      if (variationIds.length > 0) {
        const imageUrl = this.getProductImageUrl(
          product.id,
          windowWidth,
          windowHeight,
          variationIds,
          []
        );
        if (this.preloadedUrls.indexOf(imageUrl) === -1) {
          images.push(imageUrl);
          this.preloadedUrls.push(imageUrl);
        }
      }
    }
    this.preloadImages(images);
  }

  async preloadProductVariations(windowIndex: number, product: Product) {
    // Download the default selection for every product
    const images = [];
    const windowWidth = this.windowWidths[windowIndex]
      ? this.windowWidths[windowIndex]
      : DEFAULT_WINDOW_WIDTH;
    const windowHeight = this.windowHeights[windowIndex]
      ? this.windowHeights[windowIndex]
      : DEFAULT_WINDOW_HEIGHT;

    // Build a list of all possible combinations
    const variationIds = [];
    // const extrasIds: number[] = [];
    for (const variationType of product.variation_types) {
      const ids = [];
      for (const variation of product.product_variations) {
        if (variation.variation_type_id === variationType.id) {
          ids.push(variation.id);
        }
      }
      if (ids.length > 0) {
        variationIds.push(ids);
      }
    }
    const allCombos = cartesianProduct(...variationIds);
    for (const _variationsIds of allCombos) {
      const imageUrl = this.getProductImageUrl(
        product.id,
        windowWidth,
        windowHeight,
        _variationsIds,
        []
      );
      if (this.preloadedUrls.indexOf(imageUrl) === -1) {
        images.push(imageUrl);
        this.preloadedUrls.push(imageUrl);
      }
    }
    this.preloadImages(images);
  }

  getProductImageUrl(
    productId: number,
    windowWidth: string,
    windowHeight: string,
    variationIds: number[],
    extrasIds: number[]
  ): string {
    return `${
      process.env.REACT_APP_BACKEND_ENDPOINT
    }/api/product/image/${productId}/${windowWidth}/${windowHeight}?variations=${variationIds.join(
      ","
    )}&extras=${extrasIds.join(",")}`;
  }
}

const ProductContext = createContext(new ProductStore());

export default ProductContext;
