import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import styled from "styled-components";

import UserContext from "../contexts/UserContext";
import { Button, ModalBackdrop } from "./base-components";

const LeftButton = styled(Button)`
  position: absolute;
  left: 10px;
  top: 10px;
  width: auto;
  padding: 10px;
  margin: 0;
  z-index: 10;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  margin-right: 8px;
`;
const PlayerContainer = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  @media (min-aspect-ratio: 1280 / 720) {
    max-width: 75%;
    margin: auto;
  }
`;
const PlayerWrapper = styled.div`
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  position: relative;
`;
const WindowMeasureVideoOverlay = observer((props: { onClose: () => void }) => {
  const userContext = useContext(UserContext);

  const [visible, setVisible] = useState(true);
  const [opacity, setOpacity] = useState(0);
  const [playing, setPlaying] = useState(false);

  // Fade in on load
  useEffect(() => {
    setTimeout(function () {
      setOpacity(1);
      setPlaying(true);
    }, 100);
  }, []);

  const hideModal = () => {
    setOpacity(0);
    setTimeout(function () {
      setVisible(false);
      props.onClose();
    }, 500);
  };
  return (
    <ModalBackdrop
      style={{
        display: visible ? "block" : "none",
        opacity: opacity,
        padding: "15px",
      }}
    >
      <PlayerContainer>
        <PlayerWrapper>
          <ReactPlayer
            className="react-player"
            url={userContext.appSettings.window_measure_video}
            width="100%"
            height="100%"
            controls={true}
            playing={playing}
            config={{
              playerOptions: {
                responsive: true,
              },
            }}
          />
        </PlayerWrapper>
      </PlayerContainer>
      <LeftButton onClick={hideModal}>
        <CloseIcon icon={faTimes} />
        <span>Close</span>
      </LeftButton>
    </ModalBackdrop>
  );
});

export default WindowMeasureVideoOverlay;
