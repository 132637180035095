import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import ProductContext from "../contexts/ProductContext";
import UserContext from "../contexts/UserContext";
import { DEFAULT_WINDOW_WIDTH } from "../resources/constants";
import { color } from "../resources/styles";
import {
  Button,
  ButtonAlt,
  Link,
  ModalBackdrop,
  ModalContainer,
  ModalContents,
  Paragraph,
  TextInput,
} from "./base-components";
import WindowMeasureVideoOverlay from "./WindowMeasureVideoOverlay";

const InputContainer = styled.div`
  display: flex;
`;

const Input = styled(TextInput)`
  flex: 1;
  justify-content: stretch;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
`;

const InputSuffix = styled.div`
  flex: 0;
  padding: 0 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: ${color.lightgrey};
  border-width: 1px;
  border-style: solid;
  margin: 5px 0 10px 0;
  color: ${color.grey};
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`;

const ShuttersWarningModal = observer(() => {
  const userContext = useContext(UserContext);
  const productContext = useContext(ProductContext);

  const [measureVideoShown, setMeasureVideoShown] = useState(false);
  const [visible, setVisible] = useState(true);
  const [opacity, setOpacity] = useState(0);

  const [width, setWidth] = useState("");

  // Fade in on load
  useEffect(() => {
    setTimeout(function () {
      setOpacity(1);
    }, 100);
  }, []);

  const hideModal = () => {
    setOpacity(0);
    setTimeout(function () {
      setVisible(false);
    }, 500);
  };

  const onPressOk = () => {
    hideModal();
    productContext.setActiveWindowWidth = width;
  };

  return (
    <>
      <ModalBackdrop
        style={{ display: visible ? "block" : "none", opacity: opacity }}
      >
        <ModalContainer style={{ opacity: 1 }}>
          <ModalContents>
            <Paragraph align={"center"}>
              So we can show you the most accurate results, please enter the
              width of this window:
            </Paragraph>
            <InputContainer>
              <Input
                placeholder={DEFAULT_WINDOW_WIDTH}
                type={"number"}
                value={width}
                onChange={(e) => setWidth(e.target.value.trim())}
              />
              <InputSuffix>millimetres</InputSuffix>
            </InputContainer>
          </ModalContents>
          {userContext.appSettings.window_measure_video && (
            <Paragraph align={"center"} margin={"0 0 15px 0"} size={"0.9rem"}>
              <Link
                style={{ textDecoration: "underline" }}
                href={"#"}
                weight={"bold"}
                onClick={() => setMeasureVideoShown(true)}
              >
                Watch instructional video
              </Link>
            </Paragraph>
          )}
          <Button disabled={width === ""} onClick={onPressOk}>
            Done
          </Button>
          <ButtonAlt style={{ fontSize: "0.8rem" }} onClick={hideModal}>
            Maybe Later
          </ButtonAlt>
        </ModalContainer>
      </ModalBackdrop>
      {measureVideoShown && (
        <WindowMeasureVideoOverlay
          onClose={() => setMeasureVideoShown(false)}
        />
      )}
    </>
  );
});

export default ShuttersWarningModal;
