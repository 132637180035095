import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import styled from "styled-components";

import { Button, FlexColumn, Title } from "../components/base-components";
import ContactDetailsForm from "../components/ContactDetailsForm";
import SubmissionComplete from "../components/SubmissionComplete";
import UserContext from "../contexts/UserContext";
import { color } from "../resources/styles";

export const FullHeightView = styled.div<{ height?: string }>`
  width: 100%;
  // height: ${(props) => props.height ?? "calc(100vh - 20px)"};
  min-height: calc(100vh - 20px);
  height: calc(100vh - 20px);
  /* mobile viewport bug fix */
  min-height: calc((var(--vh, 1vh) * 100) - 20px);
  height: calc((var(--vh, 1vh) * 100) - 20px);
  overflow-y: scroll;
`;

const Container = styled(FlexColumn)`
  min-height: 100%;
`;

const TopBar = styled.div`
  flex: 0;
  position: relative;
  padding: 0 45px;
`;

const LeftButton = styled(Button)`
  width: auto;
  padding: 10px;
  margin: 0;
  z-index: 10;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 10px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  color: ${color.blue};
`;

const LeadSubmissionScreen = observer(() => {
  const userContext = useContext(UserContext);

  const onClickBack = () => {
    userContext.setSubmittingLead = false;
  };

  return (
    <FullHeightView
      height={
        userContext.isLeadContactSubmitted ? "calc(100vh - 20px)" : undefined
      }
    >
      <Container>
        {!userContext.isLeadContactSubmitted && (
          <TopBar>
            <LeftButton onClick={onClickBack}>
              <CloseIcon icon={faChevronLeft} />
            </LeftButton>
            {!userContext.isLeadContactSubmitted ? (
              <Title align={"center"}>
                {userContext.appSettings.contact_details_title_text}
              </Title>
            ) : (
              <Title align={"center"}>Done</Title>
            )}
          </TopBar>
        )}

        <FlexColumn flex={1}>
          {!userContext.isLeadContactSubmitted ? (
            <ContactDetailsForm />
          ) : (
            <SubmissionComplete />
          )}
        </FlexColumn>
      </Container>
    </FullHeightView>
  );
});

export default LeadSubmissionScreen;
