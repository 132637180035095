import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import {
  Container,
  Flex1,
  Link,
  Paragraph,
  Title,
} from "../components/base-components";
import Logo from "../components/Logo";
import { color } from "../resources/styles";

const AnimatedContainer = styled(Container)`
  position: relative;
  background-image: url("/img/background_resized.png");
  background-position: center;
  background-size: cover;
`;

const MainFlex = styled(Flex1)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;

const DarkLink = styled(Link)`
  color: ${color.black};
`;

const AccessDeniedScreen = observer(() => {
  return (
    <>
      <AnimatedContainer justify={"space-between"}>
        <Logo animated={false} />
        <MainFlex>
          <Title align={"center"}>{"access denied"}</Title>
          <Paragraph size={"1.3rem"} align={"center"}>
            You have reached the maximum login, please contact us at{" "}
            <DarkLink href={"tel:0293509999"}>02 9350 9999</DarkLink> or email
            us at{" "}
            <DarkLink href={"mailto:marketing@apolloblinds.com.au"}>
              marketing@apolloblinds.com.au
            </DarkLink>
          </Paragraph>
        </MainFlex>
      </AnimatedContainer>
    </>
  );
});

export default AccessDeniedScreen;
