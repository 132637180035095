import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  Button,
  Container,
  Flex0,
  Flex1,
  Link,
  Paragraph,
  Title,
} from "../components/base-components";
import Logo from "../components/Logo";

const Section = styled.div`
  margin-bottom: 30px;
`;

const BackButton = styled(Button)`
  max-width: 540px;
  margin: 60px auto 60px auto;
  display: block;
`;

const PrivacyScreen = (): JSX.Element => {
  const history = useHistory();
  const onClickBack = () => {
    history.goBack();
  };
  return (
    <Container>
      <Flex0>
        <Section>
          <Logo />
        </Section>
        <Section>
          <Title align={"center"}>Privacy</Title>
        </Section>
      </Flex0>

      <Flex1>
        <Section>
          <Paragraph>
            This web site is owned and operated by Apollo Window Blinds and will
            be referred to as “We”, “our” and “us” in this Internet Privacy
            Policy. By using this site, you agree to the Internet Privacy Policy
            of this web site (“the web site”), which is set out on this web site
            page. The Internet Privacy Policy relates to the collection and use
            of personal information you may supply to us through your conduct on
            the web site.
          </Paragraph>
          <Paragraph>
            We reserve the right, at our discretion, to modify or remove
            portions of this Internet Privacy Policy at any time. This Internet
            Privacy Policy is in addition to any other terms and conditions
            applicable to the web site.
          </Paragraph>
          <Paragraph>
            We recognise the importance of protecting the privacy of information
            collected about visitors to our web site, in particular information
            that is capable of identifying an individual (“personal
            information”). This Internet Privacy Policy governs the manner in
            which your personal information, obtained through the web site, will
            be dealt with. This Internet Privacy Policy should be reviewed
            periodically so that you are updated on any changes. We welcome your
            comments and feedback.
          </Paragraph>
          <Paragraph>
            Personal Information. Personal information about visitors to our
            site is collected only when knowingly and voluntarily submitted. For
            example, we may need to collect such information to provide you with
            further services or to answer or forward any requests or enquiries.
            It is our intention that this policy will protect your personal
            information from being dealt with in any way that is inconsistent
            with applicable privacy laws in Australia.
          </Paragraph>
          <Paragraph>
            Use of Information. Personal information that visitors submit to our
            site is used only for the purpose for which it is submitted or for
            such other secondary purposes that are related to the primary
            purpose, unless we disclose other uses in this Internet Privacy
            Policy or at the time of collection. Copies of correspondence sent
            from the web site, that may contain personal information, are stored
            as archives for record-keeping and back-up purposes only.
          </Paragraph>
          <Paragraph>
            Collecting information on Registered members. As part of registering
            with us, we collect personal information about you in order for you
            to take full advantage of our services. To do this it may be
            necessary for you to provide additional information to us as
            detailed below.
          </Paragraph>
          <Paragraph>
            Registration. Registration is completely optional. Registration may
            include submitting your name, email address, address, telephone
            numbers, option on receiving updates and promotional material and
            other information. You may access this information at any time by
            logging in and going to your account.
          </Paragraph>
          <Paragraph>
            Disclosure. Apart from where you have consented or disclosure is
            necessary to achieve the purpose for which it was submitted,
            personal information may be disclosed in special situations where we
            have reason to believe that doing so is necessary to identify,
            contact or bring legal action against anyone damaging, injuring, or
            interfering (intentionally or unintentionally) with our rights or
            property, users, or anyone else who could be harmed by such
            activities. Also, we may disclose personal information when we
            believe in good faith that the law requires disclosure. We may
            engage third parties to provide you with goods or services on our
            behalf. In that circumstance, we may disclose your personal
            information to those third parties in order to meet your request for
            goods or services.
          </Paragraph>
          <Paragraph>
            Security. We strive to ensure the security, integrity and privacy of
            personal information submitted to our sites, and we review and
            update our security measures in light of current technologies.
            Unfortunately, no data transmission over the Internet can be
            guaranteed to be totally secure.
          </Paragraph>
          <Paragraph>
            However, we will endeavour to take all reasonable steps to protect
            the personal information you may transmit to us or from our online
            products and services. Once we do receive your transmission, we will
            also make our best efforts to ensure its security on our systems.
          </Paragraph>
          <Paragraph>
            In addition, our employees and the contractors who provide services
            related to our information systems are obliged to respect the
            confidentiality of any personal information held by us. However, we
            will not be held responsible for events arising from unauthorised
            access to your personal information.
          </Paragraph>
          <Paragraph>
            Collecting Information for Users. IP Addresses: Our web servers
            gather your IP address to assist with the diagnosis of problems or
            support issues with our services. Again, information is gathered in
            aggregate only and cannot be traced to an individual user.
          </Paragraph>
          <Paragraph>
            Cookies and Applets: We use cookies to provide you with a better
            experience. These cookies allow us to increase your security by
            storing your session ID and are a way of monitoring single user
            access. This aggregate, non-personal information is collated and
            provided to us to assist in analysing the usage of the site.
          </Paragraph>
          <Paragraph>
            Access to Information. We will endeavour to take all reasonable
            steps to keep secure any information which we hold about you, and to
            keep this information accurate and up to date. If, at any time, you
            discover that information held about you is incorrect, you may
            contact us to have the information corrected.
          </Paragraph>
          <Paragraph>
            In addition, our employees and the contractors who provide services
            related to our information systems are obliged to respect the
            confidentiality of any personal information held by us.
          </Paragraph>
          <Paragraph>
            Problems or questions. If we become aware of any ongoing concerns or
            problems with our web sites, we will take these issues seriously and
            work to address these concerns. If you have any further queries
            relating to our Privacy Policy, or you have a problem or complaint,
            please contact us.
          </Paragraph>
          <Paragraph>
            For more information about privacy issues in Australia and
            protecting your privacy, visit the Australian Federal Privacy
            Commissioner’s{" "}
            <Link href={"http://www.privacy.gov.au/"} target={"_blank"}>
              website
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <BackButton onClick={onClickBack}>Go Back</BackButton>
        </Section>
      </Flex1>
    </Container>
  );
};

export default PrivacyScreen;
