import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Konva from "konva";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Circle, Group, Image, Line, Shape } from "react-konva";
import { Html } from "react-konva-utils";
import styled from "styled-components";
import useImage from "use-image";

import ProductContext from "../contexts/ProductContext";
import useWindowSize from "../lib/useWindowResize";
import { BOTTOM_BAR_OFFSET } from "../resources/constants";
import done_image from "../resources/img/done.png";
import drag_image from "../resources/img/drag_image.png";
import { color } from "../resources/styles";
import { ProductConfig, ProductSelection, ProductVariation } from "../types";

function getMidPoint(a1: number, a2: number, b1: number, b2: number): number {
  // Find the midpoint of two ranges

  const minA = Math.min(a1, a2);
  const maxA = Math.max(a1, a2);
  const minB = Math.min(b1, b2);
  const maxB = Math.max(b1, b2);

  const midA = minA + (maxA - minA) / 2;
  const midB = minB + (maxB - minB) / 2;

  const min = Math.min(midA, midB);
  const max = Math.max(midA, midB);
  return min + (max - min) / 2;
}

const LoaderIcon = styled(FontAwesomeIcon)`
  margin: auto;
  font-size: 50px;
  color: ${color.orange};
`;
const DragImage = () => {
  const [image] = useImage(drag_image);
  const size = useWindowSize();

  const wWidth = size && size.width ? size.width : window.innerWidth;
  const wHeight = size && size.height ? size.height : window.innerHeight;
  const aspect = wWidth / wHeight;

  let offset = BOTTOM_BAR_OFFSET;
  if (aspect > 1.6) {
    offset = 0;
  }

  const windowWidth = wWidth;
  const windowHeight = wHeight - offset;

  let initialLeft = windowWidth / 2 - 60;
  let initialTop = windowHeight / 3 - 60;
  let dimens = 120;

  if (aspect > 1.6) {
    initialLeft = windowWidth / 2 - 45;
    initialTop = windowHeight / 2 - 45;
    dimens = 90;
  }

  return (
    <Image
      image={image}
      x={initialLeft}
      y={initialTop}
      width={dimens}
      height={dimens}
    />
  );
};

const ConfirmButton = (props: {
  onClick: () => void;
  topLeft: number[];
  topRight: number[];
  bottomLeft: number[];
  bottomRight: number[];
}) => {
  const [image] = useImage(done_image);
  // Get the mid point of the parent
  const x =
    getMidPoint(
      props.topLeft[0],
      props.topRight[0],
      props.bottomLeft[0],
      props.bottomRight[0]
    ) - 50;
  const y =
    getMidPoint(
      props.topLeft[1],
      props.bottomLeft[1],
      props.topRight[1],
      props.bottomRight[1]
    ) - 10;

  return (
    <Group onClick={props.onClick} onTap={props.onClick} x={x} y={y}>
      <Image image={image} width={100} height={31} />
    </Group>
  );
};

const Loader = (props: {
  topLeft: number[];
  topRight: number[];
  bottomLeft: number[];
  bottomRight: number[];
}) => {
  // Get the mid point of the parent
  const x =
    getMidPoint(
      props.topLeft[0],
      props.topRight[0],
      props.bottomLeft[0],
      props.bottomRight[0]
    ) - 25;
  const y =
    getMidPoint(
      props.topLeft[1],
      props.bottomLeft[1],
      props.topRight[1],
      props.bottomRight[1]
    ) - 25;

  return (
    <Group x={x} y={y}>
      <Html>
        <LoaderIcon icon={faSpinner} pulse />
      </Html>
    </Group>
  );
};

// eslint-disable-next-line react/display-name
const VariationImage = (props: {
  windowIndex: number;
  variations: ProductVariation[];
  extrasIds: number[];
  productConfig: ProductConfig | null | undefined;
  groupPos: number[];
  topLeft: number[];
  topRight: number[];
  bottomLeft: number[];
  bottomRight: number[];
  windowWidth: number;
  windowHeight: number;
}) => {
  let topLeftX = props.topLeft[0] + props.groupPos[0];
  let topLeftY = props.topLeft[1] + props.groupPos[1];
  let topRightX = props.topRight[0] + props.groupPos[0];
  let topRightY = props.topRight[1] + props.groupPos[1];
  let bottomRightX = props.bottomRight[0] + props.groupPos[0];
  let bottomRightY = props.bottomRight[1] + props.groupPos[1];
  let bottomLeftX = props.bottomLeft[0] + props.groupPos[0];
  let bottomLeftY = props.bottomLeft[1] + props.groupPos[1];

  if (props.productConfig) {
    let offsetTop = 0;
    let offsetLeft = 0;
    let offsetRight = 0;
    let offsetBottom = 0;
    // Get the bounds of all the points so we can calculate the width/height of the window
    const boundsMinX = Math.min(topLeftX, topRightX, bottomRightX, bottomLeftX);
    const boundsMaxX = Math.max(topLeftX, topRightX, bottomRightX, bottomLeftX);
    const boundsMinY = Math.min(topLeftY, topRightY, bottomRightY, bottomLeftY);
    const boundsMaxY = Math.max(topLeftY, topRightY, bottomRightY, bottomLeftY);
    const boundsWidth = boundsMaxX - boundsMinX;
    const boundsHeight = boundsMaxY - boundsMinY;
    if (props.productConfig.offset_top) {
      offsetTop = parseFloat(props.productConfig.offset_top);
    }
    if (props.productConfig.offset_left) {
      offsetLeft = parseFloat(props.productConfig.offset_left);
    }
    if (props.productConfig.offset_right) {
      offsetRight = parseFloat(props.productConfig.offset_right);
    }
    if (props.productConfig.offset_bottom) {
      offsetBottom = parseFloat(props.productConfig.offset_bottom);
    }
    topLeftY = topLeftY - boundsHeight * offsetTop;
    topRightY = topRightY - boundsHeight * offsetTop;

    topLeftX = topLeftX - boundsWidth * offsetLeft;
    bottomLeftX = bottomLeftX - boundsWidth * offsetLeft;

    topRightX = topRightX + boundsWidth * offsetRight;
    bottomRightX = bottomRightX + boundsWidth * offsetRight;

    bottomLeftY = bottomLeftY + boundsHeight * offsetBottom;
    bottomRightY = bottomRightY + boundsHeight * offsetBottom;
  }

  // Get the bounds of all the points
  const boundsMinX = Math.min(topLeftX, topRightX, bottomRightX, bottomLeftX);
  const boundsMaxX = Math.max(topLeftX, topRightX, bottomRightX, bottomLeftX);
  const boundsMinY = Math.min(topLeftY, topRightY, bottomRightY, bottomLeftY);
  const boundsMaxY = Math.max(topLeftY, topRightY, bottomRightY, bottomLeftY);
  const boundsWidth = boundsMaxX - boundsMinX;
  const boundsHeight = boundsMaxY - boundsMinY;

  if (!props.variations.length) {
    const dummyCanvas = document.querySelector(
      "#dummy-canvas"
    ) as HTMLCanvasElement;
    const dummyContext = dummyCanvas ? dummyCanvas.getContext("2d") : null;
    if (dummyCanvas && dummyContext) {
      dummyContext.clearRect(boundsMinX, boundsMinY, boundsWidth, boundsHeight);
    }
    return null;
  }
  // We need to request a custom image
  // Calculate the window dimensions

  const variationIds = props.variations.map((item) => item.id);

  const controlPointsTopLeft = [
    (Math.abs(topLeftX - boundsMinX) / boundsWidth).toFixed(6),
    (Math.abs(topLeftY - boundsMinY) / boundsHeight).toFixed(6),
  ];

  const controlPointsTopRight = [
    (Math.abs(topRightX - boundsMaxX) / boundsWidth).toFixed(6),
    (Math.abs(topRightY - boundsMinY) / boundsHeight).toFixed(6),
  ];

  const controlPointsBottomLeft = [
    (Math.abs(bottomLeftX - boundsMinX) / boundsWidth).toFixed(6),
    (Math.abs(bottomLeftY - boundsMaxY) / boundsHeight).toFixed(6),
  ];

  const controlPointsBottomRight = [
    (Math.abs(bottomRightX - boundsMaxX) / boundsWidth).toFixed(6),
    (Math.abs(bottomRightY - boundsMaxY) / boundsHeight).toFixed(6),
  ];

  const imageUrl = `${
    process.env.REACT_APP_BACKEND_ENDPOINT
  }/api/product/image/${props.variations[0].product_id}/${props.windowWidth}/${
    props.windowHeight
  }?variations=${variationIds.join(",")}&extras=${props.extrasIds.join(
    ","
  )}&widthpx=${boundsWidth.toFixed(0)}&cptl=${controlPointsTopLeft.join(
    ","
  )}&cptr=${controlPointsTopRight.join(
    ","
  )}&cpbl=${controlPointsBottomLeft.join(
    ","
  )}&cpbr=${controlPointsBottomRight.join(",")}`;

  const [image, state] = useImage(imageUrl, "anonymous");

  // We use a dummy canvas, as the Konva canvas 'flashes' when the user makes changes
  const dummyCanvas = document.querySelector(
    `#dummy-canvas-${props.windowIndex}`
  ) as HTMLCanvasElement;
  const dummyContext = dummyCanvas ? dummyCanvas.getContext("2d") : null;
  if (image && dummyCanvas && dummyContext) {
    dummyContext.clearRect(0, 0, dummyCanvas.width, dummyCanvas.height);
    dummyContext.drawImage(
      image,
      boundsMinX,
      boundsMinY,
      boundsWidth,
      boundsHeight
    );
  }

  if (state !== "loaded") {
    return (
      <Loader
        topLeft={props.topLeft}
        topRight={props.topRight}
        bottomLeft={props.bottomLeft}
        bottomRight={props.bottomRight}
      />
    );
  }

  return null;
};

const CornerHandle = (props: {
  pointOrigin: number[];
  pointX: number[];
  pointY: number[];
  corner: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  active?: boolean;
  onDrag: (e: Konva.KonvaEventObject<DragEvent>) => void;
  onClick: () => void;
  color: string;
}) => {
  const cornerLength = 30;
  const cornerDistance = 20;
  // Since the window can be deformed to any 4 side polygonal shape,
  // we need to draw the corner handle corner lines at the same angles
  const angleA = Math.atan2(
    props.pointX[1] - props.pointOrigin[1],
    props.pointX[0] - props.pointOrigin[0]
  );

  const angleB = Math.atan2(
    props.pointY[1] - props.pointOrigin[1],
    props.pointY[0] - props.pointOrigin[0]
  );

  // Calculate new coordinates at distance of 30 along each angle
  const xA = cornerLength * Math.cos(angleA) + props.pointOrigin[0];
  const yA = cornerLength * Math.sin(angleA) + props.pointOrigin[1];

  const xB = cornerLength * Math.cos(angleB) + props.pointOrigin[0];
  const yB = cornerLength * Math.sin(angleB) + props.pointOrigin[1];

  // Line between corner and handle dot
  let angleC = Math.atan2(yB - yA, xB - xA);
  const angleCDeg = (angleC * 180) / Math.PI;

  if (["top-right", "bottom-right"].indexOf(props.corner) > -1) {
    angleC = (angleCDeg - 90) * (Math.PI / 180);
  } else {
    angleC = (angleCDeg + 90) * (Math.PI / 180);
  }

  const xC = cornerDistance * Math.cos(angleC) + props.pointOrigin[0];
  const yC = cornerDistance * Math.sin(angleC) + props.pointOrigin[1];

  return (
    <>
      <Shape
        sceneFunc={(context, shape) => {
          context.beginPath();
          context.moveTo(props.pointOrigin[0], props.pointOrigin[1]);
          context.lineTo(xA, yA);
          context.closePath();
          context.fillStrokeShape(shape);

          context.beginPath();
          context.moveTo(props.pointOrigin[0], props.pointOrigin[1]);
          context.lineTo(xB, yB);
          context.closePath();
          context.fillStrokeShape(shape);

          context.beginPath();
          context.moveTo(props.pointOrigin[0], props.pointOrigin[1]);
          context.lineTo(xC, yC);
          context.closePath();
          context.fillStrokeShape(shape);
        }}
        stroke={props.color}
        strokeWidth={6}
      />

      <Circle x={xC} y={yC} radius={10} fill={props.color} />
      <Circle
        x={props.pointOrigin[0]}
        y={props.pointOrigin[1]}
        radius={35}
        fill={"transparent"}
        draggable={props.active}
        onDragMove={props.onDrag}
        onDragEnd={props.onDrag}
        onClick={props.onClick}
        onTouchStart={props.onClick}
      />
    </>
  );
};

type WindowProps = {
  color: string;
  onMove: (windowIndex: number) => void;
  onClick: (windowIndex: number) => void;
  onInteract: () => void;
  onClickConfirm: () => void;
  onWidthChange?: (
    oldWidth: number,
    newWidth: number,
    newHeight: number
  ) => void;
  focused: boolean;
  active: boolean;
  windowIndex: number;
  showDragImage: boolean;
  productSelection: ProductSelection | null | undefined;
  productVariations: ProductVariation[];
  extrasIds: number[];
  windowWidth: number; // User defined width
  windowHeight: number; // User defined height (auto calculated)
};
const Window = observer((props: WindowProps) => {
  const productContext = useContext(ProductContext);
  const helperHandleColour = color.transparent;
  const size = useWindowSize();

  const wWidth = size && size.width ? size.width : window.innerWidth;
  const wHeight = size && size.height ? size.height : window.innerHeight;
  const aspect = wWidth / wHeight;

  let offset = BOTTOM_BAR_OFFSET;
  if (aspect > 1.6) {
    offset = 0;
  }

  const windowWidth = wWidth;
  const windowHeight = wHeight - offset;

  let initialLeft = windowWidth / 2 - 80;
  let initialRight = windowWidth / 2 + 80;
  let initialTop = windowHeight / 3 - 120;
  let initialBottom = windowHeight / 3 + 120;

  if (aspect > 1.6) {
    initialLeft = windowWidth / 2 - 60;
    initialRight = windowWidth / 2 + 60;
    initialTop = windowHeight / 2 - 90;
    initialBottom = windowHeight / 2 + 90;
  }

  const groupRef = React.useRef(null);

  const [topLeft, setTopLeft] = useState([initialLeft, initialTop]);
  const [topRight, setTopRight] = useState([initialRight, initialTop]);
  const [bottomLeft, setBottomLeft] = useState([initialLeft, initialBottom]);
  const [bottomRight, setBottomRight] = useState([initialRight, initialBottom]);
  const [groupPos, setGroupPos] = useState([0, 0]);

  const [lastUserWidth, setLastUserWidth] = useState(0);
  useEffect(() => {
    if (props.windowWidth !== lastUserWidth) {
      updateWindowDimensions(0);
      setLastUserWidth(props.windowWidth);
    }
  }, [props.windowWidth]);

  const onClick = () => {
    props.onClick(props.windowIndex);
  };

  const updateWindowDimensions = (deltaX: number) => {
    const canvasWindowWidth = Math.max(
      topRight[0] - topLeft[0],
      bottomRight[0] - bottomLeft[0]
    );
    const canvasWindowHeight = Math.max(
      bottomLeft[1] - topLeft[1],
      bottomRight[1] - topRight[1]
    );
    if (props.onWidthChange) {
      props.onWidthChange(
        canvasWindowWidth - deltaX,
        canvasWindowWidth,
        canvasWindowHeight
      );
    }
  };

  const onDragWindowEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
    if (groupRef.current && e.target !== groupRef.current) {
      return;
    }

    const x = e.target.x();
    const y = e.target.y();
    setGroupPos([x, y]);
    props.onMove(props.windowIndex);
  };

  const onDragTopBar = (e: Konva.KonvaEventObject<DragEvent>) => {
    const x = e.target.x();
    const y = e.target.y();

    // Also adjust opposing corners
    const deltaX = topLeft[0] - x;
    const deltaY = topLeft[1] - y;
    setTopLeft([topLeft[0] - deltaX, topLeft[1] - deltaY]);
    setTopRight([topRight[0] - deltaX, topRight[1] - deltaY]);
    props.onMove(props.windowIndex);
    updateWindowDimensions(0);
  };

  const onDragBottomBar = (e: Konva.KonvaEventObject<DragEvent>) => {
    const x = e.target.x();
    const y = e.target.y();

    // Also adjust opposing corners
    const deltaX = bottomLeft[0] - x;
    const deltaY = bottomLeft[1] - y;
    setBottomLeft([bottomLeft[0] - deltaX, bottomLeft[1] - deltaY]);
    setBottomRight([bottomRight[0] - deltaX, bottomRight[1] - deltaY]);
    props.onMove(props.windowIndex);
    updateWindowDimensions(0);
  };

  const onDragLeftBar = (e: Konva.KonvaEventObject<DragEvent>) => {
    const x = e.target.x();
    const y = e.target.y();

    // Also adjust opposing corners
    const deltaX = topLeft[0] - x;
    const deltaY = topLeft[1] - y;
    setTopLeft([topLeft[0] - deltaX, topLeft[1] - deltaY]);
    setBottomLeft([bottomLeft[0] - deltaX, bottomLeft[1] - deltaY]);
    props.onMove(props.windowIndex);
    updateWindowDimensions(deltaX);
  };

  const onDragRightBar = (e: Konva.KonvaEventObject<DragEvent>) => {
    const x = e.target.x();
    const y = e.target.y();

    // Also adjust opposing corners
    const deltaX = topRight[0] - x;
    const deltaY = topRight[1] - y;
    setTopRight([topRight[0] - deltaX, topRight[1] - deltaY]);
    setBottomRight([bottomRight[0] - deltaX, bottomRight[1] - deltaY]);
    props.onMove(props.windowIndex);
    updateWindowDimensions(-deltaX);
  };

  const onDragTopLeft = (e: Konva.KonvaEventObject<DragEvent>) => {
    const x = e.target.x();
    const y = e.target.y();

    // Also adjust opposing corners
    const deltaX = topLeft[0] - x;

    setTopLeft([x, y]);
    props.onMove(props.windowIndex);
    updateWindowDimensions(deltaX);
  };

  const onDragTopRight = (e: Konva.KonvaEventObject<DragEvent>) => {
    const x = e.target.x();
    const y = e.target.y();

    // Also adjust opposing corners
    const deltaX = topRight[0] - x;

    setTopRight([x, y]);
    props.onMove(props.windowIndex);
    updateWindowDimensions(-deltaX);
  };

  const onDragBottomLeft = (e: Konva.KonvaEventObject<DragEvent>) => {
    const x = e.target.x();
    const y = e.target.y();

    // Also adjust opposing corners
    const deltaX = bottomLeft[0] - x;

    setBottomLeft([x, y]);
    props.onMove(props.windowIndex);
    updateWindowDimensions(deltaX);
  };

  const onDragBottomRight = (e: Konva.KonvaEventObject<DragEvent>) => {
    const x = e.target.x();
    const y = e.target.y();

    // Also adjust opposing corners
    const deltaX = bottomRight[0] - x;

    setBottomRight([x, y]);
    props.onMove(props.windowIndex);
    updateWindowDimensions(-deltaX);
  };

  return (
    <Group
      draggable={props.active}
      onDragStart={props.onInteract}
      onTouchStart={props.onInteract}
      onClick={onClick}
      onDragEnd={onDragWindowEnd}
      ref={groupRef}
    >
      <Shape
        sceneFunc={(context, shape) => {
          context.beginPath();
          context.moveTo(topLeft[0], topLeft[1]);
          context.lineTo(topRight[0], topRight[1]);
          context.lineTo(bottomRight[0], bottomRight[1]);
          context.lineTo(bottomLeft[0], bottomLeft[1]);
          context.closePath();
          // (!) Konva specific method, it is very important
          context.fillStrokeShape(shape);
        }}
        fill={
          props.active || props.productVariations.length === 0
            ? "#FFFFFF20"
            : "transparent"
        }
        stroke={props.color}
        strokeWidth={
          props.active || props.productVariations.length === 0 ? 3 : 0
        }
        onClick={onClick}
        onTouchStart={onClick}
      />

      {!props.active && (
        <VariationImage
          key={productContext.getProductSelectionHash(props.windowIndex)}
          windowIndex={props.windowIndex}
          groupPos={groupPos}
          variations={props.productVariations}
          productConfig={
            props.productSelection
              ? props.productSelection.product?.extra_config
              : null
          }
          extrasIds={props.extrasIds}
          topLeft={topLeft}
          topRight={topRight}
          bottomLeft={bottomLeft}
          bottomRight={bottomRight}
          windowWidth={props.windowWidth}
          windowHeight={props.windowHeight}
        />
      )}

      {props.showDragImage && <DragImage />}

      {!props.showDragImage && props.active && props.focused && (
        <ConfirmButton
          onClick={props.onClickConfirm}
          topLeft={topLeft}
          topRight={topRight}
          bottomLeft={bottomLeft}
          bottomRight={bottomRight}
        />
      )}
      {props.active && (
        <>
          <Line
            x={topLeft[0]}
            y={topLeft[1]}
            points={[0, 0, topRight[0] - topLeft[0], topRight[1] - topLeft[1]]}
            draggable={props.active}
            onDragMove={onDragTopBar}
            onDragEnd={onDragTopBar}
            onClick={onClick}
            onTouchStart={onClick}
            strokeWidth={25}
            stroke={helperHandleColour}
          />
          <Line
            x={bottomLeft[0]}
            y={bottomLeft[1]}
            points={[
              0,
              0,
              bottomRight[0] - bottomLeft[0],
              bottomRight[1] - bottomLeft[1],
            ]}
            draggable={props.active}
            onDragMove={onDragBottomBar}
            onDragEnd={onDragBottomBar}
            onClick={onClick}
            onTouchStart={onClick}
            strokeWidth={25}
            stroke={helperHandleColour}
          />
          <Line
            x={topLeft[0]}
            y={topLeft[1]}
            points={[
              0,
              0,
              bottomLeft[0] - topLeft[0],
              bottomLeft[1] - topLeft[1],
            ]}
            draggable={props.active}
            onDragMove={onDragLeftBar}
            onDragEnd={onDragLeftBar}
            onClick={onClick}
            onTouchStart={onClick}
            strokeWidth={25}
            stroke={helperHandleColour}
          />
          <Line
            x={topRight[0]}
            y={topRight[1]}
            points={[
              0,
              0,
              bottomRight[0] - topRight[0],
              bottomRight[1] - topRight[1],
            ]}
            draggable={props.active}
            onDragMove={onDragRightBar}
            onDragEnd={onDragRightBar}
            onClick={onClick}
            onTouchStart={onClick}
            strokeWidth={25}
            stroke={helperHandleColour}
          />

          <CornerHandle
            corner={"top-left"}
            pointOrigin={topLeft}
            pointX={topRight}
            pointY={bottomLeft}
            active={props.active}
            onDrag={onDragTopLeft}
            onClick={onClick}
            color={props.color}
          />
          <CornerHandle
            corner={"top-right"}
            pointOrigin={topRight}
            pointX={topLeft}
            pointY={bottomRight}
            active={props.active}
            onDrag={onDragTopRight}
            onClick={onClick}
            color={props.color}
          />
          <CornerHandle
            corner={"bottom-right"}
            pointOrigin={bottomRight}
            pointX={topRight}
            pointY={bottomLeft}
            active={props.active}
            onDrag={onDragBottomRight}
            onClick={onClick}
            color={props.color}
          />
          <CornerHandle
            corner={"bottom-left"}
            pointOrigin={bottomLeft}
            pointX={topLeft}
            pointY={bottomRight}
            active={props.active}
            onDrag={onDragBottomLeft}
            onClick={onClick}
            color={props.color}
          />
        </>
      )}
    </Group>
  );
});

export default Window;
