import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { observer } from "mobx-react-lite";
import React, { useContext, useRef } from "react";
import { Image, Layer, Stage } from "react-konva";
import useImage from "use-image";

import CameraContext from "../contexts/CameraContext";
import ProductContext from "../contexts/ProductContext";
import useWindowSize from "../lib/useWindowResize";
import {
  BOTTOM_BAR_OFFSET,
  DEFAULT_WINDOW_HEIGHT,
  DEFAULT_WINDOW_WIDTH,
} from "../resources/constants";
import { color } from "../resources/styles";
import Window from "./Window";

function fit(contains: boolean) {
  // Thanks https://stackoverflow.com/a/45894506
  return (
    parentWidth: number,
    parentHeight: number,
    childWidth: number,
    childHeight: number,
    scale = 1,
    offsetX = 0.5,
    offsetY = 0.5
  ) => {
    const childRatio = childWidth / childHeight;
    const parentRatio = parentWidth / parentHeight;
    let width = parentWidth * scale;
    let height = parentHeight * scale;

    if (contains ? childRatio > parentRatio : childRatio < parentRatio) {
      height = width / childRatio;
    } else {
      width = height * childRatio;
    }

    return {
      width,
      height,
      offsetX: (parentWidth - width) * offsetX,
      offsetY: (parentHeight - height) * offsetY,
    };
  };
}

// the first very simple and recommended way:
const BackgroundImage = observer(() => {
  const cameraContext = useContext(CameraContext);
  const [image] = useImage(cameraContext.cameraImage as string);

  const size = useWindowSize();

  const aspect =
    size && size.width && size.height ? size.width / size.height : 1;

  let offset = BOTTOM_BAR_OFFSET;
  if (aspect > 1.6) {
    offset = 0;
  }

  const windowWidth = size && size.width ? size.width : window.innerWidth;
  const windowHeight =
    (size && size.height ? size.height : window.innerHeight) - offset;

  // Calculate center-point
  let x = 0;
  let y = 0;
  let width = windowWidth;
  let height = windowHeight;
  if (image) {
    x = 1;
    const constraints = fit(false)(
      windowWidth,
      windowHeight,
      image.width,
      image.height
    );
    x = constraints.offsetX;
    y = constraints.offsetY;
    width = constraints.width;
    height = constraints.height;
  }

  return <Image image={image} x={x} y={y} width={width} height={height} />;
});

const CanvasView = observer(
  (props: {
    onClickStage: () => void;
    onClickWindow: (windowIndex: number) => void;
    onClickWindowConfirm: () => void;
  }) => {
    const productContext = useContext(ProductContext);
    const size = useWindowSize();

    const aspect =
      size && size.width && size.height ? size.width / size.height : 1;

    let offset = BOTTOM_BAR_OFFSET;
    if (aspect > 1.6) {
      offset = 0;
    }

    const windowWidth = size && size.width ? size.width : window.innerWidth;
    const windowHeight =
      (size && size.height ? size.height : window.innerHeight) - offset;

    const onMoveWindow = (windowIndex: number) => {
      productContext.setHasInteractedWithWindow = true;
      productContext.setActiveWindow = windowIndex;
    };
    const onClickWindow = (windowIndex: number) => {
      // Calling props.onClickWindow before setting ActiveWindow so that
      // the VisualiserScreen can decide if to minimise bottom bar or not
      // based on the current window index
      props.onClickWindow(windowIndex);
      productContext.setActiveWindow = windowIndex;
    };

    const onInteractWindow = () => {
      productContext.setHasInteractedWithWindow = true;
    };

    const onWindowWidthChange = (
      windowIndex: number,
      oldWidth: number,
      newWidth: number,
      newHeight: number
    ) => {
      if (productContext.windowWidths[windowIndex]) {
        const currentUserWidth = parseInt(
          productContext.windowWidths[windowIndex],
          10
        );
        const scale = currentUserWidth / oldWidth;
        const delta = newWidth - oldWidth;
        const change = (delta * scale) | 0;
        const newUserWidth = currentUserWidth + change;

        // Calculate the height of window in mm based on window dimensions and the user's
        // inputted window width
        const heightScale = newUserWidth / newWidth;
        const newUserHeight = (newHeight * heightScale) | 0;

        productContext.setWindowWidth = {
          index: windowIndex,
          width: newUserWidth.toString(),
        };

        productContext.setWindowHeight = {
          index: windowIndex,
          height: newUserHeight.toString(),
        };
      }
    };

    const stageRef = useRef<Konva.Stage>(null);

    const onClickConfirm = () => {
      props.onClickWindowConfirm();
    };

    const onClickStage = (e: KonvaEventObject<Event>) => {
      if (stageRef.current && e.target !== stageRef.current) {
        // Clicked something else - let the window click handler take care of it
        return;
      }
      props.onClickStage();
    };

    return (
      <>
        <div className={"canvas-wrapper"} id={"camera-canvas-wrapper"}>
          <Stage width={windowWidth} height={windowHeight}>
            <Layer>
              <BackgroundImage />
            </Layer>
          </Stage>
        </div>

        {productContext.hasConfirmedWindows && (
          <>
            <div className={"canvas-wrapper"}>
              <canvas
                id={"dummy-canvas-0"}
                width={windowWidth}
                height={windowHeight}
              />
            </div>
            <div className={"canvas-wrapper"}>
              <canvas
                id={"dummy-canvas-1"}
                width={windowWidth}
                height={windowHeight}
              />
            </div>
            <div className={"canvas-wrapper"}>
              <canvas
                id={"dummy-canvas-2"}
                width={windowWidth}
                height={windowHeight}
              />
            </div>
          </>
        )}

        <div className={"main-canvas"} id={"main-canvas-wrapper"}>
          <Stage
            width={windowWidth}
            height={windowHeight}
            ref={stageRef}
            onTap={onClickStage}
          >
            <Layer>
              <Window
                onClick={onClickWindow}
                onInteract={onInteractWindow}
                onClickConfirm={onClickConfirm}
                color={
                  productContext.activeWindowIndex === 0
                    ? color.white
                    : color.grey
                }
                focused={productContext.activeWindowIndex === 0}
                onMove={onMoveWindow}
                onWidthChange={(oldWidth, newWidth, newHeight) =>
                  onWindowWidthChange(0, oldWidth, newWidth, newHeight)
                }
                windowIndex={0}
                active={!productContext.hasConfirmedWindows}
                showDragImage={!productContext.hasInteractedWithWindow}
                productSelection={productContext.getProductSelection(0)}
                productVariations={productContext.getProductVariationSelection(
                  0
                )}
                extrasIds={productContext.getProductExtrasSelection(0)}
                windowWidth={parseInt(
                  productContext.windowWidths.length > 0 &&
                    productContext.windowWidths[0]
                    ? productContext.windowWidths[0]
                    : DEFAULT_WINDOW_WIDTH
                )}
                windowHeight={parseInt(
                  productContext.windowHeights.length > 0 &&
                    productContext.windowHeights[0]
                    ? productContext.windowHeights[0]
                    : DEFAULT_WINDOW_HEIGHT
                )}
              />
              {productContext.numWindows > 1 && (
                <Window
                  onClick={onClickWindow}
                  onInteract={onInteractWindow}
                  onClickConfirm={onClickConfirm}
                  color={
                    productContext.activeWindowIndex === 1
                      ? color.orange
                      : color.grey
                  }
                  focused={productContext.activeWindowIndex === 1}
                  onMove={onMoveWindow}
                  onWidthChange={(oldWidth, newWidth, newHeight) =>
                    onWindowWidthChange(1, oldWidth, newWidth, newHeight)
                  }
                  windowIndex={1}
                  active={!productContext.hasConfirmedWindows}
                  showDragImage={false}
                  productSelection={productContext.getProductSelection(1)}
                  productVariations={productContext.getProductVariationSelection(
                    1
                  )}
                  extrasIds={productContext.getProductExtrasSelection(1)}
                  windowWidth={parseInt(
                    productContext.windowWidths.length > 1 &&
                      productContext.windowWidths[1]
                      ? productContext.windowWidths[1]
                      : DEFAULT_WINDOW_WIDTH
                  )}
                  windowHeight={parseInt(
                    productContext.windowHeights.length > 1 &&
                      productContext.windowHeights[1]
                      ? productContext.windowHeights[1]
                      : DEFAULT_WINDOW_HEIGHT
                  )}
                />
              )}
              {productContext.numWindows > 2 && (
                <Window
                  onClick={onClickWindow}
                  onInteract={onInteractWindow}
                  onClickConfirm={onClickConfirm}
                  color={
                    productContext.activeWindowIndex === 2
                      ? color.yellow
                      : color.grey
                  }
                  focused={productContext.activeWindowIndex === 2}
                  onMove={onMoveWindow}
                  windowIndex={2}
                  onWidthChange={(oldWidth, newWidth, newHeight) =>
                    onWindowWidthChange(2, oldWidth, newWidth, newHeight)
                  }
                  active={!productContext.hasConfirmedWindows}
                  showDragImage={false}
                  productSelection={productContext.getProductSelection(2)}
                  productVariations={productContext.getProductVariationSelection(
                    2
                  )}
                  extrasIds={productContext.getProductExtrasSelection(2)}
                  windowWidth={parseInt(
                    productContext.windowWidths.length > 2 &&
                      productContext.windowWidths[2]
                      ? productContext.windowWidths[2]
                      : DEFAULT_WINDOW_WIDTH
                  )}
                  windowHeight={parseInt(
                    productContext.windowHeights.length > 2 &&
                      productContext.windowHeights[2]
                      ? productContext.windowHeights[2]
                      : DEFAULT_WINDOW_HEIGHT
                  )}
                />
              )}
            </Layer>
          </Stage>
        </div>
      </>
    );
  }
);

export default CanvasView;
