import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 90%;
  max-width: 320px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  animation-delay: 3s;
  animation-duration: 0.5s;
  animation-name: fadeout;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
`;

const LogoContainer = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  height: 0;
  padding-top: 30.6%;
  overflow: hidden;
`;

const Sun = styled.div`
  position: absolute;
  left: 5.5%;
  top: 14%;
  width: 10.11%;
  height: auto;
  overflow: hidden;

  transform: translateY(-25%);
  animation-delay: 1s;
  animation-duration: 1s;
  animation-name: logoSunWrapperAnim;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  svg {
    transform: translateX(100%);
    animation-delay: 1s;
    animation-duration: 1s;
    animation-name: logoSunAnim;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
  }

  .cls-sun {
    fill: #ffe600;
  }
`;

const Window = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 16.29%;
  height: auto;

  animation-delay: 0s;
  animation-duration: 1s;
  animation-name: logoWindowAnim;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  .cls-window {
    fill: #00447c;
  }
`;

const Text = styled.div`
  position: absolute;
  left: 21.34%;
  top: 0;
  width: 78.65%;
  height: 100%;
  overflow: hidden;

  animation-delay: 0s;
  animation-duration: 1s;
  animation-name: logoTextWrapperAnim;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  svg {
    animation-delay: 0s;
    animation-duration: 1s;
    animation-name: logoTextAnim;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  .cls-1-text {
    fill: #f15825;
  }
  .cls-2-text {
    fill: #00447c;
  }
`;

export default function LogoAnimation(): JSX.Element {
  return (
    <Wrapper>
      <LogoContainer>
        <Sun>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.95 23.28">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  className="cls-sun"
                  d="M18,1.92c1,.65,1.27,18.54.36,19.19A11.64,11.64,0,1,1,18,1.92"
                />
              </g>
            </g>
          </svg>
        </Sun>
        <Window>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.09 54.18">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  className="cls-window"
                  d="M0,54.18H29.09V0H0ZM25.42,25.77H3.55V3.21H25.42ZM3.55,27.58H25.42v6.6H3.55ZM25.42,43.49H3.55v-5.8H25.42ZM3.55,48.26H25.42V51.2H3.55Z"
                />
              </g>
            </g>
          </svg>
        </Window>
        <Text>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140.83 54.12">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  className="cls-1-text"
                  d="M12.38,53.93H9.56V48.06H4.05v5.87H1.22V40.22H4.05v5.56H9.56V40.22h2.82Z"
                />
                <path
                  className="cls-1-text"
                  d="M31.9,47.39a8.24,8.24,0,0,1-.72,3.55,5.34,5.34,0,0,1-2,2.35,6.05,6.05,0,0,1-6.1,0A5.38,5.38,0,0,1,21,51a8.08,8.08,0,0,1-.75-3.5v-.67A8.37,8.37,0,0,1,21,43.22,5.43,5.43,0,0,1,23,40.86a5.63,5.63,0,0,1,3-.83,5.69,5.69,0,0,1,3.05.83,5.41,5.41,0,0,1,2.06,2.36,8.17,8.17,0,0,1,.73,3.56ZM29,46.77a5.82,5.82,0,0,0-.77-3.28,2.74,2.74,0,0,0-4.4,0,5.63,5.63,0,0,0-.78,3.25v.67a5.82,5.82,0,0,0,.77,3.26,2.72,2.72,0,0,0,4.41,0A5.94,5.94,0,0,0,29,47.43Z"
                />
                <path
                  className="cls-1-text"
                  d="M43.43,40.22l3.52,10,3.51-10h3.71V53.93H51.33V50.18l.28-6.47-3.7,10.22H46L42.28,43.72l.28,6.46v3.75H39.74V40.22Z"
                />
                <path
                  className="cls-1-text"
                  d="M70.67,48H65.25v3.67h6.36v2.27H62.42V40.22h9.17v2.29H65.25v3.27h5.42Z"
                />
                <path
                  className="cls-1-text"
                  d="M84.12,50.53l3.11-10.31h3.14L85.6,53.93H82.65L77.9,40.22H81Z"
                />
                <path
                  className="cls-1-text"
                  d="M100.45,53.93H97.63V40.22h2.82Z"
                />
                <path
                  className="cls-1-text"
                  d="M117.11,48h-5.43v3.67h6.37v2.27h-9.19V40.22H118v2.29h-6.35v3.27h5.43Z"
                />
                <path
                  className="cls-1-text"
                  d="M136.17,50,138,40.22h2.81l-3,13.71H135l-2.23-9.17-2.24,9.17h-2.84l-3-13.71h2.81L129.27,50l2.26-9.74h2.39Z"
                />
                <path
                  className="cls-2-text"
                  d="M13.35,0H10.56L0,26.19H2.68L5.83,18H17.75l3.19,8.15h2.68ZM6.6,15.92,11.84,2.49l5.07,13.43Z"
                />
                <path
                  className="cls-2-text"
                  d="M27.87,22.59h.07a6.75,6.75,0,0,0,6.57,4.15c5.76,0,8.5-4.66,8.5-10s-2.74-10-8.5-10a7.08,7.08,0,0,0-6.75,4.15h-.07V7.26H25.56v28.9h2.31ZM34.51,8.66c4.4,0,6.2,4.14,6.2,8.06s-1.8,8.07-6.2,8.07c-4.92,0-6.64-4.14-6.64-8.07,0-4.29,1.54-8.06,6.64-8.06"
                />
                <path
                  className="cls-2-text"
                  d="M54.61,26.74c5.79,0,8.94-4.59,8.94-10s-3.15-10-8.94-10-9,4.59-9,10,3.15,10,9,10m0-18.08c4.43,0,6.63,4,6.63,8.06s-2.2,8.07-6.63,8.07S48,20.76,48,16.72s2.2-8.06,6.64-8.06"
                />
                <rect
                  className="cls-2-text"
                  x="67.04"
                  width="2.31"
                  height="26.19"
                />
                <rect
                  className="cls-2-text"
                  x="73.82"
                  width="2.31"
                  height="26.19"
                />
                <path
                  className="cls-2-text"
                  d="M88.55,26.74c5.8,0,9-4.59,9-10s-3.15-10-9-10-9,4.59-9,10,3.16,10,9,10m0-18.08c4.44,0,6.64,4,6.64,8.06s-2.2,8.07-6.64,8.07-6.64-4-6.64-8.07,2.21-8.06,6.64-8.06"
                />
              </g>
            </g>
          </svg>
        </Text>
      </LogoContainer>
    </Wrapper>
  );
}
